import ReportHeader from "../00-Header/ReportHeader";
import { IGlossary } from "./IGlossary";

const GlossaryP3En = ({ templateData, date }: IGlossary) => (
  <div id="glossary" contentEditable className="pagebreak">
    <ReportHeader
      programName={templateData.programName}
      confidential="CONFIDENTIAL"
      date={date}
      section="Glossary"
      page={templateData.page}
    />
    <main className="glossary">
      <section className="glossary-content">
        <div className="glossary-term-list">
          <p className="glossary-term-item-bold">Hurdle Rate</p>
          <p className="glossary-term-item">
            Investor’s expected rate of return to be met for the fund manager or
            FoFs to receive the Exit Bonus (see Carried Interest / Exit Bonus).
          </p>
          <p className="glossary-term-item-bold">Investment Commitment</p>
          <p className="glossary-term-item">
            Maximum amount that an investor in a Fund or FoFs commits to be
            drawndown over time through Capital Calls.
          </p>
          <p className="glossary-term-item-bold">Investment Period</p>
          <p className="glossary-term-item">
            Period in which a fund can carryout its underlying investments. In
            the case of FoFs, it applies to the period in which commitments must
            be carried out in the underlying funds,although in turn, the
            underlying funds have their own investment period to carryout the
            acquisition of their portfolio investments..
          </p>
          <p className="glossary-term-item-bold">IRR</p>
          <p className="glossary-term-item">
            Internal rate of return. It measures the annualized return on an
            investment that has negative and positive cash flows (capital calls
            and distributions) throughout the several years of a holding period.
          </p>
          <p className="glossary-term-item-bold">J-Curve Valuation</p>
          <p className="glossary-term-item">
            Period during which the valuationof a fund or FoFs is below the
            acquisition cost mainly due to the impact of the impact of
            commissions and expenses. These commissions and expenses are usually
            calculated in the Investment Commitment. However,in terms of
            valuation, only the amount disbursed is imputed, increasing its
            relative weight when (i) the drawdowns are lower than the amountof
            the Investment Commitment and ( ii ) too shorta time has passed for
            revaluationof the company / assets in the fund's portfolioor FoFs,
            which would otherwise compensate for the weight of the commissions
            and expenses.
          </p>
          <p className="glossary-term-item-bold">Leverage</p>
          <p className="glossary-term-item">
            Level of debt of an investment, generally measured as a multiple
            over EBITDA (Debt / EBITDA).
          </p>
          <p className="glossary-term-item-bold">LTM</p>
          <p className="glossary-term-item">Last Twelve Months.</p>
          <p className="glossary-term-item-bold">MOIC</p>
          <div className="glossary-term-item-special">
            <p className="glossary-term-item-special-text">
              Gross multiple on Invested Capital, calculated as:
            </p>
            <div className="glossary-term-item-form">
              <p>MOIC =</p>
              <div>
                <p className="glossary-term-item-lined">
                  Value of an Investment
                </p>
                <p>Total Cost of Investments</p>
              </div>
            </div>
          </div>
          <p className="glossary-term-item-bold">NAV</p>
          <p className="glossary-term-item">
            Net Asset Value. Generally refers to a valuation of shares net of
            fees and debt.
          </p>
          <p className="glossary-term-item-bold">Partial Divestment</p>
          <p className="glossary-term-item">
            Partial sale of a portfolio investment, typically due to an IPO
            process in which there may be restrictions on the timing of
            divestments.
          </p>
          <p className="glossary-term-item-bold">Portfolio</p>
          <p className="glossary-term-item">
            A group of several underlying investments.
          </p>
          <p className="glossary-term-item-bold">Primary Funds</p>
          <p className="glossary-term-item">
            Newly created fund (see Primary market), therefore, without an
            existing investment portfolio.
          </p>
        </div>
      </section>
    </main>
  </div>
);

export default GlossaryP3En;
