import { Col, Row } from "antd";
import PieChart, { Series } from "devextreme-react/pie-chart";

import { IPortfolioFunds } from "./IPortfolioFunds";
import { PieChartType } from "devextreme/viz/pie_chart";
import "./PortfolioFunds.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";
import { Chart, CommonSeriesSettings } from "devextreme-react/chart";

const palette = ["#B3D615", "#737373", "#ababab"];

const commonProps = {
  animation: { enabled: false },
  type: "doughnut" as PieChartType,
  legend: { visible: true },
  palette: palette,
  minSegmentSize: "150px",
  commonSeriesSettings: {
    argumentField: "column",
    type: "rangeBar",
    minBarSize: 2,
    customizeLabel() {
      return {
        backgroundColor: "none",
      };
    },
  },
};

const sources = [
  { value: "cc", name: "drawdowns" },
  { value: "dist", name: "distributions" },
];

const PortfolioFunds = ({ templateData, translations, formatNumber }: IPortfolioFunds) => {
  let page = templateData.page;

  const maxLines = 19;

  const renderPage = (fund: any, content: any, pageNum: number) => {
    page = page + 1;
    return (
      <div id="portfolioFunds" contentEditable>
        <ReportHeader
          confidential={translations.confidential}
          programName={templateData.programName}
          date={`${translations[templateData.month]} ${templateData.year}`}
          section={translations.section}
          page={pageNum}
        />
        <Row className="content" align="top" gutter={[0, 0]}>
          <Col span={24}>
            <div className="portfolioFunds-header analysis">
              <p>{fund.fund}</p>
              <img className="portfolioFunds-logo" src={fund.logo} alt="logo" />
            </div>
            <main className="portfolioFunds">
              <section className="portfolioFunds-content">{content}</section>
            </main>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <>
      {templateData.portfolioFunds.map((fund: any, index: number) => {
        let maxWidth = 0;
        for (const item of fund.portfolioAnalysis) {
          if (item.residual + item.distribution > maxWidth) {
            maxWidth = item.residual + item.distribution;
          }
        }
		//page = templateData.portfolioFunds[index - 1]?.portfolioAnalysis.length >= maxLines ? page + 3 : page + 2;

        return (
          <>
            {/* FIRST PAGE */}
            {renderPage(
              fund,
              <>
                <div className="portfolioFunds-mainBox">
                  <div className="portfolioFunds-numbers">
                    <div className="portfolioFunds-numbers-summary">
                      <div className="portfolioFunds-numbers-col">
                        <p>€{formatNumber(fund.size, 1)}</p>
                        <p>{translations.size}</p>
                      </div>
                      <div className="portfolioFunds-numbers-col">
                        <p>€{formatNumber(fund.commitment, 1)}</p>
                        <p>{translations.commitment}</p>
                      </div>
                      <div className="portfolioFunds-numbers-col">
                        <p>{formatNumber(fund.percQualitas)}%</p>
                        <p>% Qualitas</p>
                      </div>
                    </div>
                    <div className="portfolioFunds-data-summary">
                      <p>
                        {translations.origin}: <span> {fund.origin} </span> |
                      </p>
                      <p>
                        {translations.date}: <span> {fund.date} </span> |
                      </p>
                      <p>
                        {translations.type}: <span> {fund.type} </span>|
                      </p>
                      <p>
                        {translations.target}: <span> {fund.target} </span>|
                      </p>
                      <p>
                        {translations.strategy}: <span> {fund.strategy} </span>
                      </p>
                    </div>
                  </div>
                  <div className="portfolioFunds-summary">
                    <p>{translations.summary}</p>
                    <div className="portfolioFunds-summary-content">
                      <div>
                        <div className="portfolioFunds-summary-line bold">
                          <p>{translations.totalCommitment}</p>
                          <p>€{formatNumber(fund.totalCommitment, 1)}M</p>
                        </div>
                        <div className="portfolioFunds-summary-line">
                          <p>{translations.totalDrawdowns}</p>
                          <p>€{formatNumber(fund.totalDrawdowns, 1)}M</p>
                        </div>
                        <div className="portfolioFunds-summary-line">
                          <p>{translations.pendingDrawdowns}</p>
                          <p>€{formatNumber(fund.pendingDrawdowns, 1)}M</p>
                        </div>
                        <div className="portfolioFunds-summary-line bold">
                          <p>{translations.otherAdjustments}</p>
                          <p>€{formatNumber(fund.otherAdjustments, 1)}M</p>
                        </div>
                      </div>
                      <div>
                        <div className="portfolioFunds-summary-line bold">
                          <p>{translations.totalValue}</p>
                          <p>{formatNumber(fund.totalValue, 2)}x</p>
                        </div>
                        <div className="portfolioFunds-summary-line">
                          <p>{translations.nav}</p>
                          <p>€{formatNumber(fund.nav, 1)}M</p>
                        </div>
                        <div className="portfolioFunds-summary-line">
                          <p>{translations.distributions}</p>
                          <p>{formatNumber(fund.distributions, 2)}x</p>
                        </div>
                        <div className="portfolioFunds-summary-line bold">
                          <p>{translations.residual}</p>
                          <p>{formatNumber(fund.residual, 2)}x</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="portfolioFunds-evolution">
                  <p>
                    {translations.activity}.
                    <span> {translations.evolution}</span>
                  </p>
                  <div className="portfolioFunds-figures">
                    <div className="portfoliofigures-first">
                      <p>% {translations.drawdownVsCommitment}</p>
                      <div className="portfoliofigures-chart-legend">
                        <div className="portfoliofigures-chart-legend-item">
                          <div />
                          <p>{translations.current}</p>
                          <span>
                            {formatNumber(fund.compromisedChart[0].val, 1)}%
                          </span>
                          <p>
                            €{formatNumber(fund.compromisedChart[0].amount, 1)}M
                          </p>
                        </div>
                        <div className="portfoliofigures-chart-legend-item">
                          <div />
                          <p>{translations.previous}</p>
                          <span>
                            {formatNumber(fund.compromisedChart[1].val, 1)}%
                          </span>
                          <p>
                            €{formatNumber(fund.compromisedChart[1].amount, 1)}M
                          </p>
                        </div>
                        <div className="portfoliofigures-chart-legend-item">
                          <div />
                          <p>{translations.pending}</p>
                          <span>
                            {formatNumber(fund.compromisedChart[2].val, 1)}%
                          </span>
                          <p>
                            €{formatNumber(fund.compromisedChart[2].amount, 1)}M
                          </p>
                        </div>
                      </div>
                      <div className="portfoliofigures-chart">
                        <PieChart
                          dataSource={fund.compromisedChart}
                          {...commonProps}
                          margin={{ top: 0 }}
                          height={200}
                          palette={palette}
                        >
                          <Series
                            argumentField="trim"
                            valueField="val"
                            label={{
                              visible: false,
                            }}
                          />
                        </PieChart>
                      </div>
                    </div>
                    <div className="portfoliofigures-second">
                      <p>{translations.drawdownVsDistributions}</p>
                      <div className="portfoliofigures-chart-legend two-legends">
                        <div className="portfoliofigures-chart-legend-item">
                          <div />
                          <p>{translations.drawdown}</p>
                          <span>€{formatNumber(fund.drawdownTotal, 1)}M</span>
                        </div>
                        <div className="portfoliofigures-chart-legend-item">
                          <div />
                          <p>{translations.distribution}</p>
                          <span>
                            €{formatNumber(fund.distributionTotals, 1)}M
                          </span>
                        </div>
                      </div>
                      <Chart
                        dataSource={fund.ccChart}
                        margin={{ top: 10 }}
                        height={200}
                        palette={palette}
                        legend={{ visible: false }}
                        animation={false}
                        argumentAxis={{
                          label: {
                            overlappingBehavior: "hide",
                            font: { size: 8 },
                            customizeText: (e: any) => {
                              if (e.value.split(" ").length === 2) {
                                let text = e.value.split(" ");
                                return text[0].toUpperCase() + "\n" + text[1];
                              }
                              return e.value;
                            },
                          },
                          grid: {
                            visible: false,
                          },
                          tick: { visible: false },
                        }}
                        valueAxis={{
                          maxValueMargin: 0.05,
                          grid: {
                            visible: false,
                          },
                          showZero: true,
                          tick: { visible: false },
                          label: {
                            font: { size: 9 },
                          },
                        }}
                      >
                        <CommonSeriesSettings
                          type="line"
                          argumentField="date"
                          point={{ visible: false }}
                        />
                        {sources.map((item) => (
                          <Series
                            key={item.value}
                            valueField={item.value}
                            name={item.name}
                          />
                        ))}
                      </Chart>
                    </div>
                    <div className="portfoliofigures-third">
                      <p>
                        {translations.tvpi}: {translations.currentVsPrev}
                      </p>
                      <div className="portfoliofigures-chart-legend four-legends">
                        <div className="portfoliofigures-chart-legend-item">
                          <div />
                          <p>{fund.tvpi.rpiCurrent}</p>
                          <span>{formatNumber(fund.tvpi.rpiCurrentVal)}x</span>
                        </div>
                        <div className="portfoliofigures-chart-legend-item">
                          <div />
                          <p>{fund.tvpi.dpiCurrent}</p>
                          <span>{formatNumber(fund.tvpi.dpiCurrentVal)}x</span>
                        </div>
                        <div className="portfoliofigures-chart-legend-item">
                          <div />
                          <p>{fund.tvpi.rpiPrevious}</p>
                          <span>{formatNumber(fund.tvpi.rpiPreviousVal)}x</span>
                        </div>
                        <div className="portfoliofigures-chart-legend-item">
                          <div />
                          <p>{fund.tvpi.dpiPrevious}</p>
                          <span>{formatNumber(fund.tvpi.dpiPreviousVal)}x</span>
                        </div>
                      </div>
                      <div className="portfoliofigures-chart-labels">
                        <p>{formatNumber(fund.tvpi.tvpiTotalCurrent)}x</p>
                        <p>{formatNumber(fund.tvpi.tvpiTotalPrevious)}x</p>
                      </div>
                      <Chart
                        id="chart"
                        dataSource={fund.tvpiChart}
                        height={180}
                        palette={palette}
                        legend={{ visible: false }}
                        animation={false}
                        argumentAxis={{
                          visible: false,
                          grid: {
                            visible: false,
                          },
                          tick: { visible: false },
                        }}
                        valueAxis={{
                          visible: false,
                          maxValueMargin: 0.08,
                          grid: {
                            visible: false,
                          },
                          showZero: true,
                          tick: { visible: false },
                          label: {
                            visible: false,
                          },
                        }}
                      >
                        <CommonSeriesSettings
                          argumentField="quarter"
                          type="stackedbar"
                          barWidth={50}
                        />
                        <Series valueField="rpi" name="RPI"></Series>
                        <Series valueField="dpi" name="DPI"></Series>
                      </Chart>
                    </div>
                  </div>
                </div>
                <div className="portfolioFunds-relevantFacts">
                  <h4>{translations.remarkable}</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: fund.remarkableDevelopments,
                    }}
                  ></p>
                </div>
              </>,
              page
            )}

            {/* SECOND PAGE */}
            {renderPage(
              fund,
              <div className="portfolioFunds-analysis">
                <p>{translations.analysis}</p>
                <table className="portfolioFunds-analysis-table">
                  <colgroup>
                    <col style={{ width: "40px" }} />
                    {/* estado */}
                    <col style={{ width: "100px" }} />
                    {/* nombre */}
                    <col style={{ width: "90px" }} />
                    {/* pais */}
                    <col style={{ width: "90px" }} />
                    {/* ev */}
                    <col style={{ width: "55px" }} />
                    {/* fecha */}
                    <col style={{ width: "60px" }} />
                    {/* holding */}
                    <col style={{ width: "60px" }} />
                    {/* invers */}
                    <col style={{ width: "55px" }} />
                    {/* % qualitas */}
                    <col style={{ width: "80px" }} />
                    {/* moc */}
                    <col style={{ width: "50px" }} />
                    {/* opinion */}
                  </colgroup>
                  <thead>
                    <tr>
                      <th>{translations.state}*</th>
                      <th>{translations.name}</th>
                      <th>{translations.country}</th>
                      <th>{translations.ev} </th>
                      <th>{translations.dateInv}</th>
                      <th className="portfolioFunds-analysis-grey">
                        {translations.holdingPeriod}
                      </th>
                      <th className="portfolioFunds-analysis-grey">
                        {translations.investment}
                      </th>
                      <th className="portfolioFunds-analysis-grey">
                        % QUALITAS
                      </th>
                      <th className="portfolioFunds-analysis-grey">MOC **</th>
                      <th className="portfolioFunds-analysis-grey">
                        {translations.opinion}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fund.portfolioAnalysis
                      .sort(
                        (a: any, b: any) =>
                          b.residual +
                          b.distribution -
                          (a.residual + a.distribution)
                      )
                      .map((row: any, index: number) => {
                        let width =
                          ((row.distribution + row.residual) * 100) / maxWidth;
                        let distPercentage =
                          (((row.distribution * 100) /
                            (row.distribution + row.residual)) *
                            width) /
                          100;
                        if (index < maxLines) {
                          return (
                            <tr>
                              <td>{row.state}</td>
                              <td>{row.name}</td>
                              <td className="portfolioFunds-analysis-small">
                                {row.country} <br /> {row.activity}
                              </td>
                              <td>{row.ev}</td>
                              <td>{row.date}</td>
                              <td className="portfolioFunds-analysis-grey">
                                {formatNumber(row.holdingPeriod, 1)}{" "}
                                {translations.years}
                              </td>
                              <td className="portfolioFunds-analysis-grey">
                                €{formatNumber(row.investment, 1)}M
                              </td>
                              <td className="portfolioFunds-analysis-grey">
                                {formatNumber(row.qfPercentage, 2)}%
                              </td>
                              <td className="portfolioFunds-analysis-grey">
                                <div
                                  className="progress-bar"
                                  style={{
                                    background: `linear-gradient(to right, #c4c4c4 ${distPercentage}%, #fff ${distPercentage}%, #fff ${width}%,#f0f0f0 ${width}%)`,
                                  }}
                                >
                                  <span>{formatNumber(row.moc, 2)}x</span>
                                </div>
                              </td>
                              <td className="portfolioFunds-analysis-grey">
                                <div
                                  className={`manager-opinion opt-${row.opinion}`}
                                />
                              </td>
                            </tr>
                          );
                        }
                      })}
                  </tbody>
                </table>
                <div className="portfolioFunds-analysis-references">
                  <div>
                    <span>{translations.ref1}</span>
                    <br />
                    <div className="portfolioFunds-analysis-references-moc">
                      <div className="moc-reference ref-1" />
                      {translations.ref2}
                      <div className="moc-reference ref-2" />
                      {translations.ref3}
                    </div>
                  </div>
                  <div className="portfolioFunds-analysis-ref-opinions">
                    <div className="portfolioFunds-analysis-ref-opinion-item">
                      <span>{translations.option1}</span>
                      <div className="manager-opinion opt-1" />
                    </div>
                    <div className="portfolioFunds-analysis-ref-opinion-item">
                      <span>{translations.option2}</span>
                      <div className="manager-opinion opt-2" />
                    </div>
                    <div className="portfolioFunds-analysis-ref-opinion-item">
                      <span>{translations.option3}</span>
                      <div className="manager-opinion opt-3" />
                    </div>
                    <div className="portfolioFunds-analysis-ref-opinion-item">
                      <span>{translations.option4}</span>
                      <div className="manager-opinion opt-4" />
                    </div>
                  </div>
                </div>
              </div>,
              page
            )}

            {/* THIRD PAGE */}
            {fund.portfolioAnalysis.length >= maxLines &&
              renderPage(
                fund,
                <div className="portfolioFunds-analysis">
                  <p>{translations.analysis}</p>
                  <table className="portfolioFunds-analysis-table">
                    <colgroup>
                      <col style={{ width: "40px" }} />
                      {/* estado */}
                      <col style={{ width: "100px" }} />
                      {/* nombre */}
                      <col style={{ width: "90px" }} />
                      {/* pais */}
                      <col style={{ width: "90px" }} />
                      {/* ev */}
                      <col style={{ width: "55px" }} />
                      {/* fecha */}
                      <col style={{ width: "60px" }} />
                      {/* holding */}
                      <col style={{ width: "60px" }} />
                      {/* invers */}
                      <col style={{ width: "55px" }} />
                      {/* % qualitas */}
                      <col style={{ width: "80px" }} />
                      {/* moc */}
                      <col style={{ width: "50px" }} />
                      {/* opinion */}
                    </colgroup>
                    <thead>
                      <tr>
                        <th>{translations.state}*</th>
                        <th>{translations.name}</th>
                        <th>{translations.country}</th>
                        <th>{translations.ev} </th>
                        <th>{translations.dateInv}</th>
                        <th className="portfolioFunds-analysis-grey">
                          {translations.holdingPeriod}
                        </th>
                        <th className="portfolioFunds-analysis-grey">
                          {translations.investment}
                        </th>
                        <th className="portfolioFunds-analysis-grey">
                          % QUALITAS
                        </th>
                        <th className="portfolioFunds-analysis-grey">MOC **</th>
                        <th className="portfolioFunds-analysis-grey">
                          {translations.opinion}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fund.portfolioAnalysis
                        .sort(
                          (a: any, b: any) =>
                            b.residual +
                            b.distribution -
                            (a.residual + a.distribution)
                        )
                        .map((row: any, index: number) => {
                          let width =
                            ((row.distribution + row.residual) * 100) /
                            maxWidth;
                          let distPercentage =
                            (((row.distribution * 100) /
                              (row.distribution + row.residual)) *
                              width) /
                            100;
                          if (index >= maxLines) {
                            return (
                              <tr>
                                <td>{row.state}</td>
                                <td>{row.name}</td>
                                <td className="portfolioFunds-analysis-small">
                                  {row.country} <br /> {row.activity}
                                </td>
                                <td>{row.ev}</td>
                                <td>{row.date}</td>
                                <td className="portfolioFunds-analysis-grey">
                                  {formatNumber(row.holdingPeriod, 1)}{" "}
                                  {translations.years}
                                </td>
                                <td className="portfolioFunds-analysis-grey">
                                  €{formatNumber(row.investment, 1)}M
                                </td>
                                <td className="portfolioFunds-analysis-grey">
                                  {formatNumber(row.qfPercentage, 2)}%
                                </td>
                                <td className="portfolioFunds-analysis-grey">
                                  <div
                                    className="progress-bar"
                                    style={{
                                      background: `linear-gradient(to right, #c4c4c4 ${distPercentage}%, #fff ${distPercentage}%, #fff ${width}%,#f0f0f0 ${width}%)`,
                                    }}
                                  >
                                    <span>{formatNumber(row.moc, 2)}x</span>
                                  </div>
                                </td>
                                <td className="portfolioFunds-analysis-grey">
                                  <div
                                    className={`manager-opinion opt-${row.opinion}`}
                                  />
                                </td>
                              </tr>
                            );
                          }
                        })}
                    </tbody>
                  </table>
                  <div className="portfolioFunds-analysis-references">
                    <div>
                      <span>{translations.ref1}</span>
                      <br />
                      <div className="portfolioFunds-analysis-references-moc">
                        <div className="moc-reference ref-1" />
                        {translations.ref2}
                        <div className="moc-reference ref-2" />
                        {translations.ref3}
                      </div>
                    </div>
                    <div className="portfolioFunds-analysis-ref-opinions">
                      <div className="portfolioFunds-analysis-ref-opinion-item">
                        <span>{translations.option1}</span>
                        <div className="manager-opinion opt-1" />
                      </div>
                      <div className="portfolioFunds-analysis-ref-opinion-item">
                        <span>{translations.option2}</span>
                        <div className="manager-opinion opt-2" />
                      </div>
                      <div className="portfolioFunds-analysis-ref-opinion-item">
                        <span>{translations.option3}</span>
                        <div className="manager-opinion opt-3" />
                      </div>
                      <div className="portfolioFunds-analysis-ref-opinion-item">
                        <span>{translations.option4}</span>
                        <div className="manager-opinion opt-4" />
                      </div>
                    </div>
                  </div>
                </div>,
                page
              )}
          </>
        );
      })}
    </>
  );
};

export default PortfolioFunds;
