import { Col, Row } from "antd";
import PieChart, {
  Series,
  Legend,
  Title,
  Font,
} from "devextreme-react/pie-chart";
import { PieChartType } from "devextreme/viz/pie_chart";

import { IPortfolioInvestments } from "./IPortfolioInvestments";
import "./PortfolioInvestments.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";

const PortfolioInvestments = ({
  templateData,
  translations,
  formatNumber
}: IPortfolioInvestments) => {

  const sumTotals = (array: any) => {
    let total: number = 0;
    for (let i = 0; i < array.length; i++) {
      total += Number(array[i].val);
    }
    return total;
  };

  const customText = (
    e: any,
    array: any[],
    wordToSearch: string,
    withValues: boolean
  ) => {
    const value = array.find((item) => item[wordToSearch] === e);
    let total = sumTotals(array);
    let percentage = formatNumber((value.val / total) * 100, 0) + "%";

    let newName = e.split(" ");
    if (newName.length === 2) {
      e = newName[0] + "\n" + newName[1];
    }
    if (newName.length === 3) {
      e = newName[0] + "\n" + newName[2];
    }
    if (withValues) {
      return `${translations[e]} ${"\n"} ${percentage} ${"\n €"} ${formatNumber(
		Number(
        value.val
      ), 1)}M`;
    } else {
      return `${e} ${"\n"} ${percentage}`;
    }
  };

  const sortLegendItems = (items: any[]) => {
    return items.sort((a: any, b: any) => {
      let itemA = a.text.toLowerCase();
      let itemB = b.text.toLowerCase();
      if (itemA < itemB) return -1;
      if (itemA > itemB) return 1;
      return 0;
    });
  };

  const commonProps = {
    animation: { enabled: false },
    type: "doughnut" as PieChartType,
    legend: {
      visible: true,
      rowItemSpacing: 14,
      font: { family: "Lato", color: "black", size: 9 },
      customizeItems: sortLegendItems,
      markerSize: 7,
    },
    palette: ["#d9d9d9", "#919191", "#000"],
    minSegmentSize: "150px",
    commonSeriesSettings: {
      argumentField: "column",
      type: "rangeBar",
      minBarSize: 2,
      customizeLabel() {
        return {
          backgroundColor: "none",
        };
      },
    },
  };

  let linesAmount =
    templateData.investmentData.length + templateData.coinvestmentData.length;

  let groups: any[] = ["Page1"];

  let groupsCopy = [...groups];
  if (templateData.coinvestmentData && linesAmount > 26) {
    groupsCopy.push("Page2");
  }
  groups = groupsCopy;

  let page = templateData.page - 1;

  const renderTable = () => (
    <table className="portfolioCoinvestments-table">
      <colgroup>
        <col style={{ width: "150px" }} />
        <col style={{ width: "70px" }} />
        <col style={{ width: "70px" }} />
        <col style={{ width: "120px" }} />
        <col style={{ width: "120px" }} />
        <col style={{ width: "120px" }} />
      </colgroup>
      <thead>
        <tr>
          <th>{translations.coinvestmentAndSecondaries}</th>
          <th>{translations.nInvestments}</th>
          <th>{translations.country}</th>
          <th>{translations.type}</th>
          <th>{translations.commitment}</th>
          <th>{translations.drawdown}</th>
        </tr>
      </thead>
      <tbody>
        {templateData.coinvestmentData.map((investment: any, index: number) => {
          if (
            index !== templateData.coinvestmentData.length - 1 &&
            index !== templateData.coinvestmentData.length - 2
          ) {
            return (
              <tr key={index}>
                <td>{investment.fund}</td>
                <td>{investment.nInvestments}</td>
                <td>{investment.country}</td>
                <td>{investment.type}</td>
                <td>€{formatNumber(investment.commitment, 0)}</td>
                <td>
                  <div
                    className="progress-bar"
                    style={{
                      background: `linear-gradient(to right, #c4c4c4 ${investment.percentage}%, #e6e6e6 ${investment.percentage}%)`,
                    }}
                  >
                    <p>{investment.percentage}%</p>
                    <p>€{formatNumber(investment.drawdowns, 0)}</p>
                  </div>
                </td>
              </tr>
            );
          } else {
            return (
              <tr key={index}>
                <td>{investment.fund}</td>
                <td>{investment.nInvestments}</td>
                <td>{investment.country}</td>
                <td>{investment.type}</td>
                <td>€{formatNumber(investment.commitment, 0)}</td>
                <td>
                  <div className="progress-bar">
                    <p>{investment.percentage}%</p>
                    <p>€{formatNumber(investment.drawdowns, 0)}</p>
                  </div>
                </td>
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );

  return (
    <>
      {groups.map((_: any, index: number) => {
        page++;
        return (
          <>
            {index === 0 ? (
              <div id="portfolioInvestments" contentEditable>
                <ReportHeader
                  confidential={translations.confidential}
                  programName={templateData.programName}
                  date={`${translations[templateData.month]} ${
                    templateData.year
                  }`}
                  section={translations.section}
                  page={page}
                />
                <Row className="content" align="top" gutter={[0, 0]}>
                  <Col span={24}>
                    <main className="portfolioInvestments">
                      <p className="portfolioInvestments-subtitle">
                        {translations.composition}
                      </p>
                      <div className="portfolioInvestments-header">
                        <div className="portfolioInvestments-headerInfo">
                          <div className="portfolioInvestments-headerInfo-item">
                            <p>{translations.investments}</p>
                            <p>{templateData.investments}</p>
                          </div>
                          <div className="portfolioInvestments-headerInfo-item">
                            <p>{translations.primaryFunds}</p>
                            <p>{templateData.primaryFunds}</p>
                          </div>
                          <div className="portfolioInvestments-headerInfo-item">
                            <p>{translations.coinvestmentsAndSecondaries}</p>
                            <p>{templateData.coinvestments}</p>
                          </div>
                        </div>
                        <div className="portfolioInvestments-chart">
                          <PieChart
                            id="portfolioInvestments-chart1"
                            dataSource={templateData.portfolioChart}
                            {...commonProps}
                            sizeGroup="portfolioPiesGroup"
                            height={"160px"}
                          >
                            <Title text={translations.diversification}>
                              <Font family={"Lato"} weight={500} size={14} />
                            </Title>
                            <Series argumentField="type" valueField="val" />
                            <Legend
                              orientation="vertical"
                              horizontalAlignment="left"
                              customizeText={(e: any) =>
                                customText(
                                  e.pointName,
                                  templateData.portfolioChart,
                                  "type",
                                  true
                                )
                              }
                            />
                          </PieChart>
                        </div>
                        <div className="portfolioInvestments-chart">
                          <PieChart
                            id="portfolioInvestments-chart1"
                            dataSource={templateData.currencyChart}
                            {...commonProps}
                            sizeGroup="portfolioPiesGroup"
                            height={"160px"}
                          >
                            <Title text={translations.diversificationCurrency}>
                              <Font family={"Lato"} weight={500} size={14} />
                            </Title>
                            <Series argumentField="currency" valueField="val" />

                            <Legend
                              orientation="vertical"
                              horizontalAlignment="left"
                              customizeText={(e: any) =>
                                customText(
                                  e.pointName,
                                  templateData.currencyChart,
                                  "currency",
                                  false
                                )
                              }
                            />
                          </PieChart>
                        </div>
                      </div>
                      <section className="portfolioInvestments-content">
                        <table className="portfolioInvestments-table">
                          <colgroup>
                            <col style={{ width: "150px" }} />
                            <col style={{ width: "70px" }} />
                            <col style={{ width: "70px" }} />
                            <col style={{ width: "120px" }} />
                            <col style={{ width: "120px" }} />
                            <col style={{ width: "120px" }} />
                          </colgroup>
                          <thead>
                            <tr>
                              <th>{translations.investmentFund}</th>
                              <th>{translations.nInvestments}</th>
                              <th>{translations.country}</th>
                              <th>{translations.type}</th>
                              <th>{translations.commitment}</th>
                              <th>{translations.drawdown}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {templateData.investmentData.map(
                              (investment: any, index: number) => {
                                if (
                                  index !==
                                  templateData.investmentData.length - 1
                                ) {
                                  return (
                                    <tr key={index}>
                                      <td>{investment.fund}</td>
                                      <td>{investment.nInvestments}</td>
                                      <td>{investment.country}</td>
                                      <td>{investment.type}</td>
                                      <td>€{formatNumber(investment.commitment, 0)}</td>
                                      <td>
                                        <div
                                          className="progress-bar"
                                          style={{
                                            background: `linear-gradient(to right, #c4c4c4 ${investment.percentage}%, #e6e6e6 ${investment.percentage}%)`,
                                          }}
                                        >
                                          <p>{investment.percentage}%</p>
                                          <p>€{formatNumber(investment.drawdowns, 0)}</p>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                } else {
                                  return (
                                    <tr key={index}>
                                      <td>{investment.fund}</td>
                                      <td>{investment.nInvestments}</td>
                                      <td>{investment.country}</td>
                                      <td>{investment.type}</td>
                                      <td>
                                        €
                                        {formatNumber(investment.commitment, 0)}
                                      </td>
                                      <td>
                                        <div className="progress-bar">
                                          <p>{investment.percentage}%</p>
                                          <p>
                                            €
                                            {formatNumber(investment.drawdowns, 0)}
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              }
                            )}
                          </tbody>
                        </table>
                        {linesAmount < 26 && renderTable()}
                      </section>
                    </main>
                  </Col>
                </Row>
              </div>
            ) : (
              <div id="portfolioInvestments" contentEditable>
                <ReportHeader
                  confidential={translations.confidential}
                  programName={templateData.programName}
                  date={`${translations[templateData.month]} ${
                    templateData.year
                  }`}
                  section={translations.section}
                  page={page++}
                />
                <Row className="content" align="top" gutter={[0, 0]}>
                  <Col span={24}>
                    <main className="portfolioInvestments">
                      <p className="portfolioInvestments-subtitle">
                        {translations.composition}
                      </p>
                      {renderTable()}
                    </main>
                  </Col>
                </Row>
              </div>
            )}
          </>
        );
      })}
    </>
  );
};

export default PortfolioInvestments;
