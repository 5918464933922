import ReportHeader from "../00-Header/ReportHeader";
import { IGlossary } from "./IGlossary";

const GlossaryP2En = ({ templateData, date }: IGlossary) => (
  <div id="glossary" contentEditable className="pagebreak">
    <ReportHeader
      programName={templateData.programName}
      confidential="CONFIDENTIAL"
      date={date}
      section="Glossary"
      page={templateData.page}
    />
    <main className="glossary">
      <section className="glossary-content">
        <div className="glossary-term-list">
          <p className="glossary-term-item-bold">Distributions</p>
          <table className="glossary-term-special-item">
            <tbody>
              <tr>
                <td>
                  <ul>
                    <li>To the Fund of Funds</li>
                  </ul>
                </td>
                <td>
                  Made as the portfolioinvestments mature and their total or
                  partial sale occurs (see Partial Divestment and Total
                  Divestment).
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>To Investors</li>
                  </ul>
                </td>
                <td>
                  Carried out as FoFs receives distributions from the underlying
                  funds.
                </td>
              </tr>
            </tbody>
          </table>
          <p className="glossary-term-item-bold">DPI</p>
          <div className="glossary-term-item-special">
            <p className="glossary-term-item-special-text">
              Distributions to Paid-In Capital". Distributions received divided
              by paid-in capital, calculated as follows:
            </p>
            <div className="glossary-term-item-form">
              <p>DPI =</p>
              <div>
                <p className="glossary-term-item-lined">
                  Distributions Received
                </p>
                <p>Total Paid in Capital</p>
              </div>
            </div>
          </div>
          <p className="glossary-term-item-bold">Drawdown Date</p>
          <p className="glossary-term-item">
            Date on which the payment of a Capital Call is made.
          </p>
          <p className="glossary-term-item-bold">Drawdowns</p>
          <table className="glossary-term-special-item">
            <tbody>
              <tr>
                <td>
                  <ul>
                    <li>For the Fund of funds</li>
                  </ul>
                </td>
                <td>
                  Amount transferredby the FoFs to underlying funds in
                  compliance with the requested Capital Calls.
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>For Investors</li>
                  </ul>
                </td>
                <td>
                  Amount transferredby the investorto the FoFs in compliance
                  with the requested Capital Calls.
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>Calculated</li>
                  </ul>
                </td>
                <td>
                  Amount transferredby the FoFs to underlying funds in
                  compliance with the requested Capital Calls.
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>Not calculated</li>
                  </ul>
                </td>
                <td>
                  Drawdown that computes out of the investmentcommitment ("Outof
                  Commitment").
                </td>
              </tr>
            </tbody>
          </table>
          <p className="glossary-term-item-bold">EBITDA</p>
          <p className="glossary-term-item">
            Earnings Before Interest, Taxes, Depreciation and Amortization.
          </p>
          <p className="glossary-term-item-bold">EV</p>
          <p className="glossary-term-item">
            Enterprise Value, including both equity and debt value.
          </p>
          <p className="glossary-term-item-bold">
            First Closing Date (Non-Promoter)
          </p>
          <p className="glossary-term-item">
            Date on which the first closing is made with third-partyinvestors,
            excluding any previous closing made by the fund management company
            or FoFs.
          </p>
          <p className="glossary-term-item-bold">FoFs</p>
          <p className="glossary-term-item">
            Fund of funds. In the context applicable here, it consists of an
            alternative investment productwhose portfoliois composed of a set of
            underlying funds in primary, secondary and joint ventures or a
            combination of these strategies (see primarymarket, secondary market
            and joint ventures).
          </p>
          <p className="glossary-term-item-bold">Fund Size</p>
          <p className="glossary-term-item">
            Accumulatedamount of Investment Commitments by investors at a given
            date.
          </p>
          <p className="glossary-term-item-bold">Fundraising Period</p>
          <p className="glossary-term-item">
            Period in which investors can make investmentcommitments in a fund.
            Once this period of fundraisingis over,the fund is closed, not
            admitting additional investors in primary(see primary market and
            objective size).
          </p>
        </div>
      </section>
    </main>
  </div>
);

export default GlossaryP2En;
