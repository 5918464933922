import ReportHeader from "../00-Header/ReportHeader";
import { IGlossary } from "./IGlossary";

const GlossaryP1En = ({ templateData, date }: IGlossary) => (
  <div id="glossary" contentEditable className="pagebreak">
    <ReportHeader
      programName={templateData.programName}
      confidential="CONFIDENTIAL"
      date={date}
      section="Glossary"
      page={templateData.page}
    />
    <main className="glossary">
      <section className="glossary-content">
        <p className="glossary-content-title">Terms Glossary</p>
        <div className="glossary-term-list">
          <p className="glossary-term-item-bold">Buyout</p>
          <p className="glossary-term-item">
            Acquisition of a company’s shares with partial debt financing. By
            amortizing debt with the company’s future cashflows, one can
            increase the profitability of equity investments.
          </p>
          <p className="glossary-term-item-bold">CAGR</p>
          <p className="glossary-term-item">Compound Annual Growth Rate</p>
          <p className="glossary-term-item-bold">Capital Call</p>
          <table className="glossary-term-special-item">
            <tbody>
              <tr>
                <td>
                  <ul>
                    <li>To the Funds of Funds (“FoFs”)</li>
                  </ul>
                </td>
                <td>
                  Drawdown request to the FoFs as part of the Investment
                  Commitment for each of the underlying funds of the portfolio.
                  Used for the acquisition of investments and for the payment of
                  fees/expenses.
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>To Investors</li>
                  </ul>
                </td>
                <td>
                  To Investors Drawdown request to investors as part of the
                  Investment Commitment for entry into the FoFs. Used for the
                  payment of capital calls issued by underlying funds and for
                  expenses/commissions of the FoFs.
                </td>
              </tr>
            </tbody>
          </table>
          <p className="glossary-term-item-bold">Carried Interest</p>
          <p className="glossary-term-item">
            Refers to the Exit Bonus. It is the variable compensation of
            management companies and their employees (both of the underlying
            funds and of the FoFs in question), calculated according to the
            capital gains obtained by the investors. Generally, it accrues from
            a preferential rate of return for the investor (see Hurdle Rate).
          </p>
          <p className="glossary-term-item-bold">Cash Flow</p>
          <p className="glossary-term-item">Disbursements and distributions</p>
          <p className="glossary-term-item-bold">CIF</p>
          <p className="glossary-term-item">Tax identification code.</p>
          <p className="glossary-term-item-bold">CNMV</p>
          <p className="glossary-term-item">
            Spanish securities exchange commission ("Comisión Nacional Mercado
            de Valores").
          </p>
          <p className="glossary-term-item-bold">Co-investment</p>
          <p className="glossary-term-item">
            Direct investment by the FoFs in a specific asset or company. Led by
            a recognized management company on behalf of the group of
            co-investors.
          </p>
          <p className="glossary-term-item-bold">Commitment date</p>
          <p className="glossary-term-item">
            Date when Investment Commitment is finalized.
          </p>
          <p className="glossary-term-item-bold">Constitution date</p>
          <p className="glossary-term-item">
            Date when the Fund is registered with the applicable regulator.
          </p>
        </div>
      </section>
    </main>
  </div>
);

export default GlossaryP1En;
