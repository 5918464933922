import ReportHeader from "../00-Header/ReportHeader";
import { IGlossary } from "./IGlossary";

const GlossaryP2Es = ({ templateData, date }: IGlossary) => (
  <div id="glossary" contentEditable className="pagebreak">
    <ReportHeader
      programName={templateData.programName}
      confidential="CONFIDENCIAL"
      date={date}
      section="Glosario de términos"
      page={templateData.page}
    />
    <main className="glossary">
      <section className="glossary-content">
        <div className="glossary-term-list">
          <p className="glossary-term-item-bold">Curva-J de Valoración</p>
          <p className="glossary-term-item">
            Período durante el cual la valoración de un fondo o FdFs se sitúa
            por debajo del coste de adquisición debido principalmente al impacto
            que tiene la repercusión de comisiones y gastos. Dichas comisiones y
            gastos se suelen calcular sobre el Compromiso de Inversión total.
            Sin embargo, en términos de valoración se imputan solamente sobre el
            importe Desembolsado, aumentando su peso relativo cuando (i) los
            importes desembolsados son inferiores al importe del Compromiso de
            Inversión y (ii) no ha transcurrido un plazo de años suficientemente
            largo para permitir la revalorización de la empresa / activos en la
            cartera del fondo o FdFs, lo cual compensaría el peso de las
            comisiones y gastos.
          </p>
          <p className="glossary-term-item-bold">Desembolsado</p>
          <table className="glossary-term-special-item">
            <tbody>
              <tr>
                <td>
                  <ul>
                    <li>Por el Fondo de fondos</li>
                  </ul>
                </td>
                <td>
                  Importe transferido por el FdFs a fondos subyacentes en
                  cumplimiento con los Capital Calls solicitados.
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>Por los Partícipes</li>
                  </ul>
                </td>
                <td>
                  Importe transferido por el inversor al FdFs en cumplimiento
                  con los Capital Calls solicitados.
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>Computado</li>
                  </ul>
                </td>
                <td>
                  Desembolso que computa dentro del compromiso de inversión (“In
                  Commitment”).
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>No computado</li>
                  </ul>
                </td>
                <td>
                  Desembolso que computa fuera del compromiso de inversión (“Out
                  of Commitment”).
                </td>
              </tr>
            </tbody>
          </table>
          <p className="glossary-term-item-bold">Desinversión Parcial</p>
          <p className="glossary-term-item">
            Venta de una parte de las acciones de una inversión en cartera,
            típicamente debido a un proceso de salida a Bolsa en el que pueden
            existir restricciones sobre los plazos en los cuales se permite
            vender el paquete completo de acciones.
          </p>
          <p className="glossary-term-item-bold">Desinversión Total</p>
          <p className="glossary-term-item">
            Venta de todas las acciones de una inversión en cartera.
          </p>
          <p className="glossary-term-item-bold">Devoluciones de Capital</p>
          <p className="glossary-term-item">
            También referido como “Unused Capital”. Reintegro de parte del
            Desembolso realizado en un Fondo/FdFs susceptible de ser solicitado
            nuevamente por dicho Fondo/FdFs para futuras inversiones, pasando a
            formar parte del Compromiso de Inversión pendiente de desembolso.
          </p>
          <p className="glossary-term-item-bold">Distribuciones</p>
          <table className="glossary-term-special-item">
            <tbody>
              <tr>
                <td>
                  <ul>
                    <li>Realizadas al Fondo de fondos</li>
                  </ul>
                </td>
                <td>
                  Se realizan a medida que las inversiones en cartera van
                  madurando y se produce su venta total o parcial (ver
                  Desinversión Parcial y Desinversión Total).
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>Realizada a Partícipes del FdFs</li>
                  </ul>
                </td>
                <td>
                  Se realizan a medida que dicho FdFs vaya recibiendo
                  distribuciones por parte de los fondos subyacentes y sus
                  necesidades de tesorería, para hacer frente a posibles Capital
                  Calls o abonar comisiones/gastos, estén cubiertas.
                </td>
              </tr>
            </tbody>
          </table>
          <p className="glossary-term-item-bold">Distribución Recallable</p>
          <p className="glossary-term-item">
            Distribuciones recibidas de un fondo o FdFs susceptibles de ser
            solicitadas nuevamente por dicho fondo o FdFs para futuras
            inversiones, pasando a formar parte del Compromiso de Inversión
            pendiente de desembolso.
          </p>
          <p className="glossary-term-item-bold">DPI</p>
          <div className="glossary-term-item-special">
            <p className="glossary-term-item-special-text">
              “Distributed to Paid-In Capital”. Distribuciones recibidas sobre
              el importe desembolsado calculado de la siguiente forma:
            </p>
            <div className="glossary-term-item-form">
              <p>DPI =</p>
              <div>
                <p className="glossary-term-item-lined">
                  Distribuciones Recibidas
                </p>
                <p>Total Desembolsos</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
);

export default GlossaryP2Es;
