import ReportHeader from "../00-Header/ReportHeader";
import { IGlossary } from "./IGlossary";

const GlossaryP4En = ({ templateData, date }: IGlossary) => (
  <div id="glossary" contentEditable className="pagebreak">
    <ReportHeader
      programName={templateData.programName}
      confidential="CONFIDENTIAL"
      date={date}
      section="Glossary"
      page={templateData.page}
    />
    <main className="glossary">
      <section className="glossary-content">
        <div className="glossary-term-list">
          <p className="glossary-term-item-bold">Primary Market</p>
          <p className="glossary-term-item">
            An investment commitment in a newly created fund. The portfolio is
            "empty", without underlying investments, at the time of its launch.
          </p>
          <p className="glossary-term-item-bold">Private Equity</p>
          <p className="glossary-term-item">
            Taking majority or minority interests in unlisted companies for a
            period that allows for the execution of a business plan established
            at the time of acquisition,in orderto realize a surplus value when
            selling the company.
          </p>
          <p className="glossary-term-item-bold">Proforma</p>
          <p className="glossary-term-item">
            Magnitude that reflects operations developed after the date of the
            valuation referred to.
          </p>
          <p className="glossary-term-item-bold">PTC</p>
          <p className="glossary-term-item">
            Total Equity Committed (See Investment Commitment).
          </p>
          <p className="glossary-term-item-bold">Purchase Price Multiple</p>
          <p className="glossary-term-item">
            Price paid for an investment at the time of purchase, generally
            expressed as a multiple over the EBITDA of the company or asset
            purchased.
          </p>
          <p className="glossary-term-item-bold">Recallable Distribution</p>
          <p className="glossary-term-item">
            Distributions received that are susceptible to be requested again
            for future investments, becoming part of the Investment Commitment
            pending disbursement.
          </p>
          <p className="glossary-term-item-bold">Recap</p>
          <p className="glossary-term-item">Recapitalization.</p>
          <p className="glossary-term-item-bold">Return of Capital</p>
          <p className="glossary-term-item">
            Also referred to as "Unused Capital".Reimbursement of part of the
            drawdowns made in a Fund that are susceptible to be requested again
            for future investments, becoming part of the Investment Commitment
            pending disbursement.
          </p>
          <p className="glossary-term-item-bold">RoW</p>
          <p className="glossary-term-item">
            Rest of the World,generally refers to geographical areas outside of
            the main ones (e.g. Europeand the United States) to which explicit
            reference is made.
          </p>
          <p className="glossary-term-item-bold">RVPI</p>
          <div className="glossary-term-item-special">
            <p className="glossary-term-item-special-text">
              "Residual Value to Paid in Capital". Residual Value of the Live
              Portfolio over the amount disbursed, calculated as:
            </p>
            <div className="glossary-term-item-form">
              <p>RVPI =</p>
              <div>
                <p className="glossary-term-item-lined">
                  Unrealised Value of Portfolio Holdings
                </p>
                <p>Total Paid In Capital</p>
              </div>
            </div>
          </div>
          <p className="glossary-term-item-bold">SAU</p>
          <p className="glossary-term-item">
            A SAU or “Sociedad Anónima Unipersonal “ is a limited liability
            company with a sole shareholder
          </p>
          <p className="glossary-term-item-bold">Secondary Funds</p>
          <p className="glossary-term-item">
            Acquisitionof an investment commitment in an existing fund from an
            investorthat wishes to sell their participation (see Secondary
            Market).
          </p>
        </div>
      </section>
    </main>
  </div>
);

export default GlossaryP4En;
