import { Button, Flex, Modal, Select, Spin } from "antd";
import useProcedures from "../../hooks/useProcedures";
import ProceduresAPI from "../../api/ProceduresAPI";

const ProceduresModal = (props: any) => {
  const { isProceduresModalOpen, setIsProceduresModalOpen, baseUrl, getAuthToken } = props;
  const proceduresApi = ProceduresAPI({ baseUrl, getAuthToken});
  const { 
	closeProceduresModal,
	onChangeQuarter,
	startProcess,
	quartersList,
	selectedQuarter,
	loading
} = useProcedures({ setIsProceduresModalOpen, proceduresApi });

  const processes = [
	{title: 'Cartera Empresas', process: 'sp_Trim_CarteraEmpresas'},
	{title: 'CF Datos', process: 'sp_Trim_CFDatos'},
	{title: 'Fichas Fondos Coinversión', process: 'sp_Trim_FichasFondosCoinv'}
  ]
  return (
    <Modal
      title="Start Procedures"
      open={isProceduresModalOpen}
	  centered
      footer={null}
	  width={500}
      onCancel={closeProceduresModal}
    >
		<Spin spinning={loading} >
			<p>Please select the Quarter and an option to start the process:</p>
			<Flex gap="middle" vertical align="center" justify="center" style={{height: '280px'}}>
				<Select
					onChange={(e) => onChangeQuarter(e)}
					options={quartersList}
					placeholder="Select a Quarter"
					style={{width: '220px', marginTop: '10px'}}
				/>
				{processes.map((process: any) => {
					return <Button 
						className="process-btn"
						disabled={!selectedQuarter}
						onClick={()=> startProcess(process.process)}
						key={process.title}>
							<p>{process.title} 
								<br/>
								<span>{process.process}</span>
							</p>
						</Button>
					})
				}
			</Flex>
		</Spin>
    </Modal>
  );
};

export default ProceduresModal;
