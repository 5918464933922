import ReportHeader from "../00-Header/ReportHeader";
import { IGlossary } from "./IGlossary";

const GlossaryP1Es = ({ templateData, date }: IGlossary) => (
  <div id="glossary" contentEditable className="pagebreak">
    <ReportHeader
      programName={templateData.programName}
      confidential="CONFIDENCIAL"
      date={date}
      section="Glosario de términos"
      page={templateData.page}
    />
    <main className="glossary">
      <section className="glossary-content">
        <p className="glossary-content-title">Glosario de términos</p>
        <div className="glossary-term-list">
          <p className="glossary-term-item-bold">Apalancamiento</p>
          <p className="glossary-term-item">
            Nivel de endeudamiento de una inversión, generalmente medido como un
            múltiplo sobre EBITDA (Deuda / EBITDA).
          </p>
          <p className="glossary-term-item-bold">Añada (“Vintage”)</p>
          <p className="glossary-term-item">
            Año en que se lleva a cabo la primera inversión de un fondo.
          </p>
          <p className="glossary-term-item-bold">Buyout</p>
          <p className="glossary-term-item">
            Operación de adquisición de las acciones de una empresa, cuyo precio
            se financia parcialmente con deuda. El objetivo es que dicha deuda
            se amortice con el cash flow generado en el futuro por la propia
            empresa, aumentando así la rentabilidad sobre la parte del precio
            financiada con capital
          </p>
          <p className="glossary-term-item-bold">
            Capital Call (Llamada de Capital)
          </p>
          <table className="glossary-term-special-item">
            <tbody>
              <tr>
                <td>
                  <ul>
                    <li>Al fondo de Fondos (“FdFs”)</li>
                  </ul>
                </td>
                <td>
                  Solicitud de desembolso al FdFs como parte del Compromiso de
                  Inversión adquirido en cada uno de los fondos subyacentes de
                  la cartera. Con el propósito de o bien hacer frente a pagos
                  por la adquisición de inversiones o bien abonar
                  gastos/comisiones del fondo subyacente.
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>A los Partícipes</li>
                  </ul>
                </td>
                <td>
                  Solicitud de desembolso a los inversores como parte del
                  Compromiso de Inversión adquirido en su fecha de entrada en el
                  FdFs. Su propósito es o bien hacer frente a pagos de capital
                  calls emitidos por los subyacente o bien abonar
                  gastos/comisiones del FdFs
                </td>
              </tr>
            </tbody>
          </table>
          <p className="glossary-term-item-bold">Carried Interest</p>
          <p className="glossary-term-item">
            Término referido a la Comisión de Éxito. Es la Remuneración variable
            a favor de las sociedades gestoras o sus empleados (tanto de los
            fondos subyacentes como del Fondo de fondos en cuestión), calculada
            en función de las plusvalías obtenidas por los inversores.
            Generalmente se devenga a partir de una tasa de rentabilidad
            preferente para el inversor (ver hurdle rate).
          </p>
          <p className="glossary-term-item-bold">Cash Flow</p>
          <p className="glossary-term-item">
            Flujo de Caja, desembolsos y distribuciones.
          </p>
          <p className="glossary-term-item-bold">CIF</p>
          <p className="glossary-term-item">Código de Identificación Fiscal.</p>
          <p className="glossary-term-item-bold">CNMV</p>
          <p className="glossary-term-item">
            Comisión Nacional del Mercado de Valores
          </p>
          <p className="glossary-term-item-bold">Coinversión</p>
          <p className="glossary-term-item">
            Inversión por parte del FdFs en un activo o empresa específico de
            manera directa y de la mano de una sociedad gestora de reconocido
            prestigio que lidere la inversión por cuenta del grupo de
            coinversores.
          </p>
          <p className="glossary-term-item-bold">Compromiso de Inversión</p>
          <p className="glossary-term-item">
            Importe máximo al que un inversor en un Fondo o FdFs se compromete a
            desembolsar. Dicho compromiso de inversión será solicitado a lo
            largo del tiempo a través de Capital Calls.
          </p>
        </div>
      </section>
    </main>
  </div>
);

export default GlossaryP1Es;
