import { Col, Row } from "antd";
import Chart, { ArgumentAxis, CommonSeriesSettings, Format, Label, Legend, Series, ValueAxis } from 'devextreme-react/chart';

import { IFundFigures } from "./IFundFiguresFoF";

import "./FundFiguresFoF.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";

const FundFiguresFoF = ({ templateData, translations, formatNumber }: IFundFigures) => (
	<div id="fundFigures" contentEditable>
		<ReportHeader
			programName={templateData.programName}
			confidential={translations.confidential}
			date={`${translations[templateData.month]} ${templateData.year}`}
			section={translations.section}
			page={templateData.page}
		/>
		<Row className="content" align="middle" gutter={[0, 0]}>
			<Col>
				<main className="fundFigures">
					<section className="fundFigures-content">
						<p className="fundFigures-subtitle">
							{translations.fundFiguresUpdate}
						</p>
						<table className="fundFigures-table">
							<colgroup>
								<col style={{width: "300px"}} />
								<col style={{width: "100px"}} />
								<col style={{width: "50px"}} />
								<col style={{width: "30px"}} />
								<col style={{width: "100px"}} />
								<col style={{width: "40px"}} />
								<col style={{width: "30px"}} />
								<col style={{width: "90px"}} />
							</colgroup>
							<thead>
							<tr className="fundFigures-table-beforeBold">
								<th className="fundFigures-table-title"></th>
								<th className="fundFigures-table-title fundFigures-table-beforeBold" colSpan={3}>
									{translations.currentQuarter}
								</th>
								<th className="fundFigures-table-title" colSpan={3}>
									{translations.previousQuarter}
								</th>
								<th className="fundFigures-table-title">
									{translations.variation}
								</th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td className="fundFigures-table-subtitle">
									{translations.totalCommitment}
								</td>
								<td className="fundFigures-table-coloredCells">
									{`€${formatNumber(templateData.commitCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-coloredCells">
									{`${formatNumber(templateData.commitCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-lightGrey">
									(1)
								</td>
								<td>{`€${formatNumber(templateData.commitPQValue, 0)}`}</td>
								<td>{`${formatNumber(templateData.commitPQPercentage, 0)}%`}</td>
								<td className="fundFigures-table-lightGrey">(1)</td>
								<td>{`€${formatNumber(templateData.commitVariation,0)}`}</td>
							</tr>
							<tr>
								<td>{translations.totalPaidCapital}</td>
								<td className="fundFigures-table-coloredCells">
									{`€${formatNumber(templateData.drawdownCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-coloredCells">
									{`${formatNumber(templateData.drawdownCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-lightGrey">
									(1)
								</td>
								<td>{`€${formatNumber(templateData.drawdownPQValue, 0)}`}</td>
								<td>{`${formatNumber(templateData.drawdownPQPercentage, 0)}%`}</td>
								<td className="fundFigures-table-lightGrey">(1)</td>
								<td>{`€${formatNumber(templateData.drawdownVariation, 0)}`}</td>
							</tr>
							<tr>
								<td>{translations.cumulativeDistributions}</td>
								<td className="fundFigures-table-coloredCells">
									{`€${formatNumber(templateData.distCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-coloredCells">
									{`${formatNumber(templateData.distCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-lightGrey">
									(2)
								</td>
								<td>{`€${formatNumber(templateData.distPQValue, 0)}`}</td>
								<td>{`${formatNumber(templateData.distPQPercentage, 0)}%`}</td>
								<td className="fundFigures-table-lightGrey">(2)</td>
								<td>{`€${formatNumber(templateData.distVariation, 0)}`}</td>
							</tr>
							<tr className="fundFigures-table-beforeBold">
								<td>{translations.unfundedCommitment}</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-beforeBold">
									{`€${formatNumber(templateData.pendCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-beforeBold">
									{`${formatNumber(templateData.pendCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-lightGrey fundFigures-table-beforeBold">
									(1)
								</td>
								<td>{`€${formatNumber(templateData.pendPQValue, 0)}`}</td>
								<td>{`${formatNumber(templateData.pendPQPercentage, 0)}%`}</td>
								<td className="fundFigures-table-lightGrey">(1)</td>
								<td>{`€${formatNumber(templateData.pendVariation, 0)}`}</td>
							</tr>
							<tr >
								<td className="fundFigures-table-subtitle">
									{translations.totalCommitmentPortfolio}
								</td>
								<td className="fundFigures-table-coloredCells">
									{`€${formatNumber(templateData.commitFundsCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-coloredCells">
									{`${formatNumber(templateData.commitFundsCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-lightGrey">
									(1)
								</td>
								<td>
									{`€${formatNumber(templateData.commitFundsPQValue, 0)}`}
								</td>
								<td>{`${templateData.commitFundsPQPercentage}%`}</td>
								<td className="fundFigures-table-lightGrey">(1)</td>
								<td>
									{`€${formatNumber(templateData.commitFundsVariation, 0)}`}
								</td>
							</tr>
							<tr>
								<td>{translations.totalInvestedPortfolio}</td>
								<td className="fundFigures-table-coloredCells">
									{`€${formatNumber(templateData.drawdownFundsCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-coloredCells">
									{`${formatNumber(templateData.drawdownFundsCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-lightGrey">
									(2)
								</td>
								<td>
									{`€${formatNumber(templateData.drawdownFundsPQValue, 0)}`}
								</td>
								<td>{`${formatNumber(templateData.drawdownFundsPQPercentage, 0)}%`}</td>
								<td className="fundFigures-table-lightGrey">(2)</td>
								<td>
									{`€${formatNumber(templateData.drawdownFundsVariation, 0)}`}
								</td>
							</tr>
							<tr className="fundFigures-table-beforeBold">
								<td>{translations.cumulativeDistributionsPortfolio}</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-beforeBold">
									{`€${formatNumber(templateData.distFundsCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-beforeBold">
									{`${formatNumber(templateData.distFundsCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-lightGrey fundFigures-table-beforeBold">
									(2)
								</td>
								<td>{`€${formatNumber(templateData.distFundsPQValue, 0)}`}</td>
								<td>{`${formatNumber(templateData.distFundsPQPercentage, 0)}%`}</td>
								<td className="fundFigures-table-lightGrey">(2)</td>
								<td>
									{`€${formatNumber(templateData.distFundsVariation, 0)}`}
								</td>
							</tr>
							<tr>
								<td className="fundFigures-table-subtitle">
									{translations.totalFairValue}
								</td>
								<td className="fundFigures-table-coloredCells">
									{`€${formatNumber(templateData.portfolioCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-coloredCells">
									{`${formatNumber(templateData.portfolioCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-lightGrey">
									(2)
								</td>
								<td>{`€${formatNumber(templateData.portfolioPQValue, 0)}`}</td>
								<td>{`${formatNumber(templateData.portfolioPQPercentage, 0)}%`}</td>
								<td className="fundFigures-table-lightGrey">(2)</td>
								<td>
									{`€${formatNumber(templateData.portfolioVariation, 0)}`}
								</td>
							</tr>
							<tr className="fundFigures-table-beforeBold">
								<td>{translations.netCashPosition}</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-beforeBold">
									{`€${formatNumber(templateData.netCashCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-beforeBold">
									{`${formatNumber(templateData.netCashCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-lightGrey fundFigures-table-beforeBold">
									(2)
								</td>
								<td>{`€${formatNumber(templateData.netCashPQValue, 0)}`}</td>
								<td>{`${formatNumber(templateData.netCashPQPercentage, 0)}%`}</td>
								<td className="fundFigures-table-lightGrey">(2)</td>
								<td>{`€${formatNumber(templateData.netCashVariation, 0)}`}</td>
							</tr>
							<tr>
								<td className="fundFigures-table-subtitle fundFigures-table-green">
									{translations.totalNetAssetValue}
								</td>
								<td className="fundFigures-table-green">
									{`€${formatNumber(templateData.totalNavCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-green">
									{`${formatNumber(templateData.totalNavCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-green fundFigures-table-lightGrey">
									(2)
								</td>
								<td className="fundFigures-table-green">
									{`€${formatNumber(templateData.totalNavPQValue, 0)}`}
								</td>
								<td className="fundFigures-table-green">
									{`${formatNumber(templateData.totalNavPQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-green fundFigures-table-lightGrey">
									(2)
								</td>
								<td className="fundFigures-table-green">
									{`€${formatNumber(templateData.totalNavVariation, 0)}`}
								</td>
							</tr>
							<tr className="fundFigures-table-rows">
								<td>{translations.grossMultipleCost}</td>
								<td className="fundFigures-table-coloredCells" colSpan={3}>
									{`${formatNumber(templateData.grossMocCQ)}x`}
								</td>
								<td colSpan={3}>{`${templateData.grossMocPQ}x`}</td>
								<td>{`${formatNumber(templateData.grossMocVariation)}x`}</td>
							</tr>
							<tr className="fundFigures-table-rows fundFigures-table-beforeBold">
								<td>{translations.grossIRR}</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-beforeBold" colSpan={3}>
									{`${formatNumber(templateData.grossTirCQ)}%`}
								</td>
								<td colSpan={3}>{`${formatNumber(templateData.grossTirPQ)}%`}</td>
								<td>{`${formatNumber(templateData.grossTirVariation)}%`}</td>
							</tr>
							<tr className="fundFigures-table-rows">
								<td className="fundFigures-table-subtitle">
									{translations.distributionsPaidCapital}
								</td>
								<td className="fundFigures-table-coloredCells" colSpan={3}>
									{`${formatNumber(templateData.dpiCQ)}x`}
								</td>
								<td colSpan={3}>{`${templateData.dpiPQ}x`}</td>
								<td>{`${formatNumber(templateData.dpiVariation)}x`}</td>
							</tr>
							<tr className="fundFigures-table-rows">
								<td>{translations.residualValuePaidCapital}</td>
								<td className="fundFigures-table-coloredCells" colSpan={3}>
									{`${formatNumber(templateData.rpiCQ)}x`}
								</td>
								<td colSpan={3}>{`${formatNumber(templateData.rpiPQ)}x`}</td>
								<td>{`${formatNumber(templateData.rpiVariation)}x`}</td>
							</tr>
							<tr className="fundFigures-table-rows">
								<td>{translations.totalValuePaidCapital}</td>
								<td className="fundFigures-table-coloredCells" colSpan={3}>
									{`${formatNumber(templateData.tvpiCQ)}x`}
								</td>
								<td colSpan={3}>{`${formatNumber(templateData.tvpiPQ)}x`}</td>
								<td>{`${formatNumber(templateData.tvpiVariation)}x`}</td>
							</tr>
							<tr className="fundFigures-table-rows">
								<td>{translations.netIRR}</td>
								<td className="fundFigures-table-coloredCells" colSpan={3}>
									{`${formatNumber(templateData.netTirCQ)}%`}
								</td>
								<td colSpan={3}>{`${formatNumber(templateData.netTirPQ)}%`}</td>
								<td>{`${formatNumber(templateData.netTirVariation)}%`}</td>
							</tr>
							<tr className="fundFigures-table-rows">
								<td>{translations.paidCapitalToComCapital}</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-beforeBold" colSpan={3}>
								{`${formatNumber(templateData.drawdownCommitCQ, 0)}%`}
								</td>
								<td colSpan={3}>{`${formatNumber(templateData.drawdownCommitPQ, 0)}%`}</td>
								<td>{`${formatNumber(templateData.drawdownCommitVariation, 0)}%`}</td>
							</tr>
							</tbody>
						</table>
						<table>
							<tr>
								<td className="fundFigures-references">
									<strong>(1)</strong> {translations.ref1}
								</td>
								<td className="fundFigures-references">
									<strong>(2)</strong> {translations.ref2}
								</td>
								<td className="fundFigures-references">
									<strong>(3)</strong> {translations.ref3}
								</td>
							</tr>
						</table>
					</section>					
					<section className="fundFigures-content">		
						<p className="fundFigures-subtitle">
							{translations.investmentDivestment}. 
							<span> {translations.activity}</span>
						</p>
						<Chart
							dataSource={templateData.fundEvolutionPortfolioChart}
							animation={{ enabled: false }}
							palette={['#dbdbdb', '#6B6B6B']}
							height="360px"
							argumentAxis={{
								label: {
									overlappingBehavior: 'none',
								},
								grid: {
									visible: false,
								},
								tick: { visible: false },
							}}
							valueAxis={{
								visible: false,
								maxValueMargin: 0.05,
								label: {
									visible: false,
								},
								grid: {
									visible: false,
								},
								showZero: true,
								tick: { visible: false },
								autoBreaksEnabled: true,
								maxAutoBreakCount: 0,
								breakStyle: {
									line: 'straight',
								},
							}}
							>
							<CommonSeriesSettings
								argumentField="column"
								barPadding={5}
								type="bar"
								barWidth={150}
							>
								<Label
									visible={true}
									position="outside"
									backgroundColor="none"
									font={{ color: 'black', size: '9px' }}
									customizeText={(e: any) => {
										return (
											'€' + formatNumber(e.originalValue, 1) + 'M'
										);
									}}
									verticalOffset={8}
								/>
							</CommonSeriesSettings>
							<Series valueField="last" name={translations.previousPeriod}/>
							<Series valueField="current" name={translations.currentPeriod}/>
							<Legend verticalAlignment="bottom" horizontalAlignment="center" />
							<ArgumentAxis>
								<Label 
									visible={true} 
									font={{ color: 'black', size: '10px' }} 
									customizeText={(e: any) => translations[e.value]}
								/>
							</ArgumentAxis>
							<ValueAxis>
								<Label visible={false} />
							</ValueAxis>
						</Chart>

					</section>
				</main>
			</Col>
		</Row>
	</div>
);

export default FundFiguresFoF;