import ReportHeader from "../00-Header/ReportHeader";
import { IGlossary } from "./IGlossary";

const GlossaryP5En = ({ templateData, date }: IGlossary) => (
  <div id="glossary" contentEditable className="pagebreak">
    <ReportHeader
      programName={templateData.programName}
      confidential="CONFIDENTIAL"
      date={date}
      section="Glossary"
      page={templateData.page}
    />
    <main className="glossary">
      <section className="glossary-content">
        <div className="glossary-term-list">
          <p className="glossary-term-item-bold">Secondary Market</p>
          <p className="glossary-term-item">
            Acquisition of an investment commitment in an existing fund from an
            investor who wishes to sell their participation. The new investor
            replaces the previous one in identical terms and conditions, buying
            the portion of the investment commitment that is already paid at a
            price agreed between the parties and assuming the commitment pending
            drawdown. When carried out in older funds, they already have
            underlying investments in the portfolio.
          </p>
          <p className="glossary-term-item-bold">SGIIC</p>
          <p className="glossary-term-item">
            "Sociedad Gestora de Instituciones de Inversión Colectiva” oversees
            and regulates the operations of investment institutions.
          </p>
          <p className="glossary-term-item-bold">Target Size</p>
          <p className="glossary-term-item">
            Reflects the anticipated accumulation of Investment Commitments by
            investors once the Fundraising Period of a fund or FoFs ends.
          </p>
          <p className="glossary-term-item-bold">Total Divestment</p>
          <p className="glossary-term-item">
            Sale of all shares of an investmentin the portfolio.
          </p>
          <p className="glossary-term-item-bold">Track Record</p>
          <p className="glossary-term-item">
            Historical returns obtained by preceding funds managed by the same
            company or team.
          </p>
          <p className="glossary-term-item-bold">Underlying Funds</p>
          <p className="glossary-term-item">
            Funds that are part of the FoFs portfolio.
          </p>
          <p className="glossary-term-item-bold">Underlying Investment</p>
          <p className="glossary-term-item">
            Assets / companies in which you have invested through an underlying
            fund.
          </p>
          <p className="glossary-term-item-bold">Valuation</p>
          <p className="glossary-term-item">
            MOIC / TVPI / Multiple on Capital invested (see MOIC or TVPI).
          </p>
          <p className="glossary-term-item-bold">VC</p>
          <p className="glossary-term-item">Venture Capital Fund.</p>
          <p className="glossary-term-item-bold">Venture Capital</p>
          <p className="glossary-term-item">
            Private Capital in companies that are in the early stages of
            development.
          </p>
          <p className="glossary-term-item-bold">Vintage</p>
          <p className="glossary-term-item">
            Year in which the fund makes its first investments.
          </p>
          <p className="glossary-term-item-bold">Write-off</p>
          <p className="glossary-term-item">
            Deduction realized on the capital invested in a company or asset.
          </p>
          <p className="glossary-term-item-bold">Yield</p>
          <p className="glossary-term-item">
            Recurring distributions (usually annual).
          </p>
        </div>
      </section>
    </main>
  </div>
);

export default GlossaryP5En;
