import PieChart, {
  Series,
  Legend,
  Title,
  Font,
} from "devextreme-react/pie-chart";
import { PieChartType } from "devextreme/viz/pie_chart";

import { IPortfolioDiversification } from "./IPortfolioDiversification";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "./PortfolioDiversification.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";

const PortfolioDiversification = ({ templateData, translations, formatNumber }: IPortfolioDiversification) => {
  
	const sumTotals = (array: any) => {
    let total: number = 0;
    for (let i = 0; i < array.length; i++) {
      total += Number(array[i].val);
    }
    return total;
  };

  const customText = (e: any, array: any[], wordToSearch: string) => {
  
    const value = array.find((item) => item[wordToSearch] === e);

    let total = sumTotals(array);
    let percentage = formatNumber(((value.val / total) * 100), 1) + "%";

    let newName = e.split(" ");
    if (newName.length === 2) {
      e = newName[0].substr(0,8) + "\n" + newName[1].substr(0,8) ;
    }
    if (newName.length === 3) {
      e = newName[0].substr(0,8)  + "\n" + newName[2].substr(0,8) ;
    }
    if (newName.length === 4) {
      e = newName[0].substr(0,8) + "\n" + newName[3].substr(0,8) ;
    }
    if (newName.length === 5) {
      e = newName[0].substr(0,8) + "\n" + newName[1].substr(0,8) ;
    }

    return `${e.substr(0, 17)} ${"\n"} ${percentage}`;
  };

  const customSize = (e: any, array: any[], wordToSearch: string) => {
	const value = array.find((item) => item[wordToSearch] === e);
    let total = sumTotals(array);

	let percentage = formatNumber(((value.val / total) * 100), 1) + "%";

    let newName = e.replace(' ', '').split("-");
    return `${newName[0]} ${"\n"} ${newName[1] ?? ''} ${"\n"} ${percentage}`
  }

  const sortLegendItems = (items: any[]) => {
    return items.sort((a: any, b: any) => {
      let itemA = a.text.toLowerCase();
      let itemB = b.text.toLowerCase();
      if (itemA < itemB) return -1;
      if (itemA > itemB) return 1;
      return 0;
    });
  }
  const commonProps = {
    animation: { enabled: false },
    type: "doughnut" as PieChartType,
    legend: {
      visible: true,
      rowItemSpacing: 8,
      font: { family: "Lato", color: "black", size: 8},
      customizeItems: sortLegendItems,
      markerSize: 7,
      margin: {top: 0, left: 5},
      columnCount: 2,
    },
    palette: ["#d9d9d9", "#919191", "#000"],
    minSegmentSize: "150px",
    commonSeriesSettings: {
      argumentField: "column",
      type: "rangeBar",
      minBarSize: 2,
      customizeLabel() {
        return {
          backgroundColor: "none",
        };
      },
    },
  };

  const renderCenter = (number: any)=> {  
    return (
    <svg>
      <circle cx="100" cy="110" r={15} fill="#ffffff"></circle>
      <text textAnchor="middle" x="100" y="120" style={
        { fontSize: 24, fontFamily: '"Palatino", serif', fontWeight: 500 }
        }>
        <tspan x="100">{number}</tspan>
      </text>
    </svg>
  );}


  return (
    <div id="portfolioCharts" contentEditable>
      <ReportHeader
        confidential={translations.confidential}
        programName={templateData.programName}
        date={`${translations[templateData.month]} ${templateData.year}`}
        section={translations.section}
        page={templateData.page}
      />
      <main className="portfolioCharts">
        <section className="portfolioCharts-container">
          <div>
            <p className="portfolioCharts-subtitle">
              {translations.portfolioDiversificationAnalysis}
            </p>
            <p>{translations.period}</p>
          </div>
          <div className="portfolioCharts-boxes">
            <div className="portfolioCharts-infoBox">
              <p>{translations.companies}</p>
              <span>{templateData.companies}</span>
            </div>
            <div className="portfolioCharts-infoBox">
              <p>{translations.countries}</p>
              <span>{templateData.countries}</span>
            </div>
            <div className="portfolioCharts-infoBox">
              <p>{translations.sectors}</p>
              <span>{templateData.sectors}</span>
            </div>
          </div>
        </section>
        <section className="portfolioCharts-exposure">
          <p>{translations.exposureAnalysis}</p>
          <table className="portfolioCharts-table">
            <thead>
              <tr>
                <th></th>
                {templateData.exposureTable.some((row: any) => row.dach !== 0) && <th>DACH</th>}
                {templateData.exposureTable.some((row: any) => row.nordic !== 0) && <th>{translations.nordic}</th>}
                {templateData.exposureTable.some((row: any) => row.uk !== 0) && <th>{translations.uk}</th>}
                {templateData.exposureTable.some((row: any) => row.france !== 0) && <th>{translations.france}</th>}
                {templateData.exposureTable.some((row: any) => row.italy !== 0) && <th>{translations.italy}</th>}
                {templateData.exposureTable.some((row: any) => row.usa !== 0) && <th>{translations.usa}</th>}
                {templateData.exposureTable.some((row: any) => row.benelux !== 0) && <th>{translations.benelux}</th>}
                <th>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              {templateData.exposureTable.map((row: any) => (
                <tr>
                  <td>{row.sector}</td>
                  {templateData.exposureTable.some((row: any) => row.dach !== 0) && <td>{formatNumber(row.dach)}%</td>}
                  {templateData.exposureTable.some((row: any) => row.nordic !== 0) && <td>{formatNumber(row.nordic)}%</td>}
                  {templateData.exposureTable.some((row: any) => row.uk !== 0) && <td>{formatNumber(row.uk)}%</td>}
                  {templateData.exposureTable.some((row: any) => row.france !== 0) && <td>{formatNumber(row.france)}%</td>}
                  {templateData.exposureTable.some((row: any) => row.italy !== 0) && <td>{formatNumber(row.italy)}%</td>}
                  {templateData.exposureTable.some((row: any) => row.usa !== 0) && <td>{formatNumber(row.usa)}%</td>}
                  {templateData.exposureTable.some((row: any) => row.benelux !== 0) && <td>{formatNumber(row.benelux)}%</td>}
                  <td>{formatNumber(row.total)}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
        <section className="portfolioCharts-charts">
          <p>{translations.diversification}</p>
          <div className="portfolioCharts-chartsContainer">
            <PieChart
              id="portfolioCharts-chart1"
              dataSource={templateData.investmentYearChart}
              {...commonProps}
              sizeGroup="piesGroup"
              width={300}
              centerRender={()=> renderCenter(templateData.investmentYearChart.length)}
            >
              <Title text={translations.investmentYear}>
                <Font family={"Lato"} weight={500} size={14} />
              </Title>
              <Series argumentField="year" valueField="val" />
              <Legend
                orientation="vertical"
                horizontalAlignment="left"
                paddingTopBottom={0}
                customizeText={(e: any) =>
                  customText(
                    e.pointName,
                    templateData.investmentYearChart,
                    "year"
                  )
                }
              />
            </PieChart>
            <PieChart
              id="portfolioCharts-chart1"
              dataSource={templateData.sectorChart}
              {...commonProps}
              sizeGroup="piesGroup"
              width={300}
              centerRender={()=> renderCenter(templateData.sectorChart.length)}
            >
              <Title text={translations.sectors}>
                <Font family={"Lato"} weight={500} size={14} />
              </Title>
              <Series argumentField="sector" valueField="val" />
              <Legend
                orientation="vertical"
                horizontalAlignment="left"
                customizeText={(e: any) =>
                  customText(e.pointName, templateData.sectorChart, "sector" )
                }
              />
            </PieChart>
            <PieChart
              id="portfolioCharts-chart2"
              dataSource={templateData.regionChart}
              {...commonProps}
              sizeGroup="piesGroup"
              width={300}
              centerRender={()=> renderCenter(templateData.regionChart.length)}
            >
              <Title text={translations.regions}>
                <Font family={"Lato"} weight={500} size={14} />
              </Title>
              <Series
                argumentField="region"
                valueField="val"
                label={{ visible: false }}
              />
              <Legend
                orientation="vertical"
                horizontalAlignment="left"
                customizeText={(e: any) =>
                  customText(e.pointName, templateData.regionChart, "region")
                }
              />
            </PieChart>
            <PieChart
              id="portfolioCharts-chart3"
              dataSource={templateData.enterpriseValueChart}
              {...commonProps}
              sizeGroup="piesGroup"
              width={300}
              centerRender={()=> renderCenter(templateData.enterpriseValueChart.length)}
            >
              <Title text={translations.enterpriseValue}>
                <Font family={"Lato"} weight={500} size={14} />
              </Title>
              <Series
                argumentField="value"
                valueField="val"
                label={{ visible: false }}
              />
              <Legend
                orientation="vertical"
                horizontalAlignment="left"
                rowCount={10}
                 customizeText={(e: any) =>
                  customSize(
                    e.pointName,
					templateData.enterpriseValueChart,
					"value"
                  )
                }
             />
            </PieChart>
          </div>
        </section>
      </main>
    </div>
  );
};

export default PortfolioDiversification;
