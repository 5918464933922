import dayjs from 'dayjs';

import { ICover } from "./ICover";

import "./Cover.scss";

const Cover = ({ templateData, translations }: ICover) => (
	<div id="cover" contentEditable className='pagebreak'>
		<main className="cover">
			<div className="cover-logoQualitas">
				<img
					src="https://qualitasfunds.blob.core.windows.net/assets/image-gallery/reporting/new_logo.png"
					alt="logo Qualitas Funds"
				/>
			</div>
			<div>
				<p className="cover-subtitle">
					{translations.coverSubtitle}
				</p>
				<h2 className="cover-title">{translations.coverTitle}</h2>
				<h2 className="cover-title">{templateData.programName}</h2>
				<p className="cover-date">{translations.coverDate} <span>{translations[templateData.month]} {templateData.year}</span></p>
				<p className="cover-confidential">{translations.coverConfidential}</p>
			</div>
		</main>
	</div>
);

export default Cover;