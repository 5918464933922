import { Col, Row } from "antd";
import Chart, { ArgumentAxis, CommonSeriesSettings, Format, Label, Legend, Series, ValueAxis } from 'devextreme-react/chart';

import { IFundEvolution } from "./IFundEvolution";

import "./FundEvolution.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";


const FundEvolution = ({ templateData, translations, formatNumber }: IFundEvolution) => (
	<div id="fundEvolution" contentEditable className="pagebreak">
		<ReportHeader
			programName={templateData.programName}
			confidential={translations.confidential}
			date={`${translations[templateData.month]} ${templateData.year}`}
			section={translations.section}
			page={templateData.page}
		/>
		<Row align="middle">
			<Col>
				<main className="fundEvolution">
					<section className="fundEvolution-content">
						<p className="fundEvolution-title">{translations.fundTitle}</p>
						<p className="fundEvolution-subtitle">{translations.portfolioSubtitle} (€M)</p>
						<Chart
							dataSource={templateData.fundEvolutionPortfolioChart}
							animation={{ enabled: false }}
							palette={['#dbdbdb', '#6B6B6B']}
							height="360px"
							argumentAxis={{
								label: {
									overlappingBehavior: 'none',
								},
								grid: {
									visible: false,
								},
								tick: { visible: false },
							}}
							valueAxis={{
								visible: false,
								maxValueMargin: 0.05,
								label: {
									visible: false,
								},
								grid: {
									visible: false,
								},
								showZero: true,
								tick: { visible: false },
								autoBreaksEnabled: true,
								maxAutoBreakCount: 0,
								breakStyle: {
									line: 'straight',
								},
							}}
							>
							<CommonSeriesSettings
								argumentField="column"
								barPadding={0}
								type="bar"
								barWidth={100}
							>
								<Label
									visible={true}
									backgroundColor="none"
									font={{ color: 'black', size: '9px' }}
									customizeText={(e: any) => {
										return (
											'€' + formatNumber(e.originalValue, 1) + 'M'
										);
									}}
								/>
							</CommonSeriesSettings>
							<Series valueField="last" name={translations.previousPeriod}/>
							<Series valueField="current" name={translations.currentPeriod}/>
							<Legend verticalAlignment="bottom" horizontalAlignment="center" />
							<ArgumentAxis>
								<Label 
									visible={true} 
									font={{ color: 'black', size: '10px' }} 
									customizeText={(e: any) => translations[e.value]}/>
							</ArgumentAxis>
							<ValueAxis>
								<Label visible={false} />
							</ValueAxis>
						</Chart>
						<div className="fundEvolution-line">
							<img 
								alt="line"
								src="https://qualitasfunds.blob.core.windows.net/assets/image-gallery/reporting/greenLine.png"/>
						</div>
						<p className="fundEvolution-subtitle">{translations.valuationSubtitle} (€M)</p>
						<Chart
							dataSource={templateData.fundValuationPortfolioChart}
							animation={{ enabled: false }}
							customizePoint={(e) => {
								if (e.minValue === 0) {
									return { color: '#6B6B6B' };
								}
								return {};
							}}
							palette={['#dbdbdb']}
							height="340px"
							argumentAxis={{
								tick: { visible: false },
							}}
							valueAxis={{
								visible: false,
								maxValueMargin: 0.05,
								label: {
									visible: false,
								},
								grid: {
									visible: false,
								},
								showZero: true,
								tick: { visible: false },
							}}
						>
							<CommonSeriesSettings
								argumentField="column"
								type="rangebar"
								minBarSize={2}
								barWidth={80}
							>
								<Label
									visible={true}
									backgroundColor="none" 
									font={{ color: 'black', size: '10px' }}
									customizeText={(e: any) => {
										if (e.originalValue === e.point.data.value1) {
											return '';
										} else if (e.originalValue === 0) {
											return '€' + e.valueText + 'm';
										} else {
											return (
												'€' + formatNumber(e.point.data.value2 - e.point.data.value1) + 'M'
											);
										}
									}}
								>
									<Format type="fixedPoint" precision={2} />
								</Label>
							</CommonSeriesSettings>
							<Series
								rangeValue1Field="value1"
								rangeValue2Field="value2"
								name="Value"
							/>
							<ArgumentAxis>
								<Label 
									visible={true} 
									font={{ color: 'black', size: '10px' }} 
									customizeText={(e: any) => translations[e.value]}
								/>
							</ArgumentAxis>
							<Legend visible={false}/>
						</Chart>
					</section>
				</main>
			</Col>
		</Row>
	</div>
);

export default FundEvolution;