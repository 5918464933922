import { Col, Row } from "antd";

import "./IndexFoF.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";
import { IIndexFoF } from "./IIndexFoF";

const IndexFoF = ({ templateData, translations }: IIndexFoF) => {

	let page = 3; 
	return ( <div id="index" contentEditable>
		<ReportHeader
			programName={templateData.programName}
			confidential={translations.confidential}
			date={`${translations[templateData.month]} ${templateData.year}`}
			section={translations.section}
			page={templateData.page}
		/>
		<Row align="middle">
			<Col>
				<main className="index">
					<section className="index-content">
						<p className="index-title">{translations.content}</p>
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>1. {translations.theFund}</Col>
							<Col span={1}>{page}</Col>
						</Row>
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>2. {translations.mainFigures}</Col>
							<Col span={1}>{page = page + templateData.section1Pages}</Col>
							<Col span={23} className="index-subSection" >2.1 {translations.fundFiguresUpdate}</Col>
							<Col span={1}>{page}</Col>
							<Col span={23} className="index-subSection" >2.2. {translations.investmentDivestment}</Col>
							<Col span={1}>{page}</Col>
						</Row>
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>3. {translations.portfolioInvestmentsInDetail}</Col>
							<Col span={1}>{page += templateData.section2Pages}</Col>
							<Col span={23} className="index-subSection" >3.1 {translations.compositionOfPortfolio}</Col>
							<Col span={1}>{page}</Col>
						</Row>
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>4. {translations.underlyingFundsCoinvestments}</Col>
							<Col span={1}>{page += templateData.section3Pages}</Col>
						</Row>
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>5. {translations.portfolioCompaniesInDetail}</Col>
							<Col span={1}>{page += templateData.section4Pages}</Col>
							<Col span={23} className="index-subSection" >5.1 {translations.portfolioDiversificationAnalysis}</Col>
							<Col span={1}>{page}</Col>
							<Col span={23} className="index-subSection" >5.2 {translations.newInvestmentsRealizations}</Col>
							<Col span={1}>{page += templateData.section5Pages}</Col>
						</Row>
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>6. {translations.newFunds}</Col>
							<Col span={1}>{page += templateData.section6Pages}</Col>
						</Row>
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>7. {translations.portfolioFundsInDetail}</Col>
							<Col span={1}>{page += templateData.section7Pages}</Col>
						</Row>
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>8. {translations.coInvestmentsSummary}</Col>
							<Col span={1}>{page += templateData.section8Pages}</Col>
							<Col span={23} className="index-subSection" >8.1 {translations.coInvestmentsSummaryTable}</Col>
							<Col span={1}>{page}</Col>
							<Col span={23} className="index-subSection" >8.1 {translations.coInvestmentsInDetail}</Col>
							<Col span={1}>{page += templateData.section9Pages}</Col>
						</Row>
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>9. {translations.fullPortfolioBreakdown}</Col>
							<Col span={1}>{page += templateData.section10Pages}</Col>
						</Row>
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>10. {translations.portfolioCompaniesInDetail}</Col>
							<Col span={1}>{page += templateData.section11Pages}</Col>
							<Col span={23} className="index-subSection">10.1 {translations.alphabeticalListing}</Col>
							<Col span={1}>{page}</Col>
						</Row>
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>11. {translations.fundDescriptionLegalTerms}</Col>
							<Col span={1}>{page += templateData.section12Pages}</Col>
						</Row>						
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>12. {translations.termsGlossary}</Col>
							<Col span={1}>{page += templateData.section13Pages}</Col>
						</Row>
					</section>
				</main>
			</Col>
		</Row>
	</div>
)};

export default IndexFoF;