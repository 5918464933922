import ReportHeader from "../00-Header/ReportHeader";
import { IGlossary } from "./IGlossary";

const GlossaryP3Es = ({ templateData, date }: IGlossary) => (
  <div id="glossary" contentEditable className="pagebreak">
    <ReportHeader
      programName={templateData.programName}
      confidential="CONFIDENCIAL"
      date={date}
      section="Glosario de términos"
      page={templateData.page}
    />
    <main className="glossary">
      <section className="glossary-content">
        <div className="glossary-term-list">
          <p className="glossary-term-item-bold">EBITDA</p>
          <p className="glossary-term-item">
            “Earnings Before Interest, Taxes, Depreciation and Amortization” /
            Resultado antes de intereses, impuestos, depreciación y
            amortización.
          </p>
          <p className="glossary-term-item-bold">EV</p>
          <p className="glossary-term-item">
            “Enterprise Value” / Valor Empresa, incluyendo tanto el valor del
            capital como de la deuda.
          </p>
          <p className="glossary-term-item-bold">FCR</p>
          <p className="glossary-term-item">Fondo de Capital Riesgo.</p>
          <p className="glossary-term-item-bold">FdFs</p>
          <p className="glossary-term-item">
            Fondo de fondos. En el contexto aquí aplicable, consiste en un
            producto de inversión alternativa cuya cartera está compuesta por un
            conjunto de fondos subyacentes en primario, secundario y
            coinversiones o una combinación de dichas estrategias (ver mercado
            primario, mercado secundario y coinversiones).
          </p>
          <p className="glossary-term-item-bold">Fecha de Compromiso</p>
          <p className="glossary-term-item">
            Fecha en la cual se adquiere el Compromiso de inversión.
          </p>
          <p className="glossary-term-item-bold">Fecha de Constitución</p>
          <p className="glossary-term-item">
            Fecha en la cual se registra el Fondo con el regulador aplicable.
          </p>
          <p className="glossary-term-item-bold">Fecha de Desembolso</p>
          <p className="glossary-term-item">
            Fecha en la cual se realiza el pago de un Capital Call.
          </p>
          <p className="glossary-term-item-bold">
            Fecha de Primer Cierre No Promotor
          </p>
          <p className="glossary-term-item">
            Fecha en la cual se realiza el primer cierre con inversores
            terceros, excluyendo cualquier cierre anterior realizado por la
            sociedad gestora de un fondo o FdFs.
          </p>
          <p className="glossary-term-item-bold">Fondos en Primario</p>
          <p className="glossary-term-item">
            Fondo de nueva creación (ver mercado Primario), por lo tanto, sin
            una cartera existente de inversiones.
          </p>
          <p className="glossary-term-item-bold">Fondos en Secundario</p>
          <p className="glossary-term-item">
            Adquisición de un compromiso de inversión en un fondo ya existente a
            un inversor que desea vender su participación (ver Mercado
            Secundario).
          </p>
          <p className="glossary-term-item-bold">Fondos Subyacentes</p>
          <p className="glossary-term-item">
            Fondos que forman parte de la cartera del FdFs.
          </p>
          <p className="glossary-term-item-bold">Hurdle Rate</p>
          <p className="glossary-term-item">
            Tasa de rentabilidad preferente para el inversor que ha de cumplirse
            para que la sociedad gestora de un fondo o FdFs tenga derecho a
            recibir la remuneración variable (ver Carried Interest /Comisión de
            éxito).
          </p>
          <p className="glossary-term-item-bold">Inversión Subyacente</p>
          <p className="glossary-term-item">
            Activos / empresas en los que se ha invertido desde un fondo
            subyacente.
          </p>
        </div>
      </section>
    </main>
  </div>
);

export default GlossaryP3Es;
