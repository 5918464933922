import { ICoinvestSummary } from "./ICoinvestSummary";

import "./CoinvestSummary.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";
import { Col, Row } from "antd";
import { GlobalOutlined } from "@ant-design/icons";
import Chart, { CommonSeriesSettings, Label, Series, ArgumentAxis } from "devextreme-react/chart";
import { useEffect, useState } from "react";

const palette = ["#B3D615", "#737373", "#ababab"];

const CoinvestSummary = ({ templateData, translations, formatNumber }: ICoinvestSummary) => {
  const [groups, setGroups] = useState<any[]>([]);

  useEffect(() => {
    let groupsCopy = [...groups];
    if (templateData.coinvestSummary) {
      templateData.coinvestSummary.forEach((item: any, index: any) => {
        const groupIndex = Math.floor(index / 2);
        if (!groupsCopy[groupIndex]) {
          groupsCopy[groupIndex] = [];
        }
        groupsCopy[groupIndex].push(item);
      });
    }
    setGroups(groupsCopy);
  }, []);

  let page = templateData.page - 1;

  return (
    <>
      {groups.map((group: any) => {
        page++;
        return (
          <div id="coinvestSummary" contentEditable className="pagebreak">
            <ReportHeader
              confidential={translations.confidential}
              programName={templateData.programName}
              date={`${translations[templateData.month]} ${templateData.year}`}
              section={translations.section}
              page={page}
            />
            <Row className="content" align="top" gutter={[0, 0]}>
              <Col span={24}>
                <main className="coinvestSummary">
                  <p className="coinvestSummary-subtitle">
                    {translations.operation}
                  </p>
                  <section className="coinvestSummary-content">
                    {group.map((item: any, index: number) => (
                      <div className="coinvestSummary-item">
                        <p className="coinvestSummary-title">
                          {item.name}{" "}
                          <span>
                            {item.country} | {item.type}{" "}
                          </span>
                        </p>
                        <div className="coinvestSummary-headerInfo">
                          <div className="coinvestSummary-headerInfo-item">
                            <p>{translations.manager}</p>
                            <p>{item.gp}</p>
                          </div>
                          <div className="coinvestSummary-headerInfo-item">
                            <p>{translations.fund} (M)</p>
                            <p>€{formatNumber(item.qfund, 1)}M</p>
                          </div>
                          <div className="coinvestSummary-headerInfo-item">
                            <p>{translations.tvpi}</p>
                            <p>{formatNumber(item.moc)}x</p>
                          </div>
                          <div className="coinvestSummary-headerInfo-item">
                            <p>{translations.date}</p>
                            <p>{item.investmentDate}</p>
                          </div>
                          <div className="coinvestSummary-headerInfo-item">
                            <p>{translations.exitDate}</p>
                            <p>{item.exitDate}</p>
                          </div>
                          <div className="coinvestSummary-headerInfo-item">
                            <p>{translations.currency}</p>
                            <p>{item.currency}</p>
                          </div>

                          <div className="coinvestSummary-headerInfo-item">
                            <img
                              className="coinvestSummary-logo"
                              src={item.logo}
                              alt="logo"
                            />
                          </div>
                        </div>
                        <div className="coinvestSummary-info">
                          <div className="coinvestSummary-info-text">
                            <p>{item.description}</p>
                            <p className="coinvestSummary-info-subtitle">
                              {translations.relevant}
                            </p>
                            <div
                              className="coinvestDetail-description-html"
                              dangerouslySetInnerHTML={{ __html: item.relevantFacts }}
                            />
                            {item.url !== "" &&
                              <p>
                                <GlobalOutlined
                                  style={{
                                    fontSize: 13,
                                    color: "grey",
                                    fontWeight: 100,
                                  }}
                                  />
                                {' '}<a href={item.url}>{item.url}</a>
                              </p>
                            }
                          </div>

                          <div className="coinvestSummary-chart">
                            <p>{translations.evolution}</p>
                            <div className="coinvestSummary-chart-legend">
                              <div className="coinvestSummary-chart-legend-item">
                                <div />
                                <p>{item.tvpi.rpiCurrent}</p>
                                <span>{formatNumber(item.tvpi.rpiCurrentVal, 2)}x</span>
                              </div>
                              <div className="coinvestSummary-chart-legend-item">
                                <div />
                                <p>{item.tvpi.dpiCurrent}</p>
                                <span>{formatNumber(item.tvpi.dpiCurrentVal, 2)}x</span>
                              </div>
                              <div className="coinvestSummary-chart-legend-item">
                                <div />
                                <p>{item.tvpi.rpiPrevious}</p>
                                <span>{formatNumber(item.tvpi.rpiPreviousVal,2)}x</span>
                              </div>
                              <div className="coinvestSummary-chart-legend-item">
                                <div />
                                <p>{item.tvpi.dpiPrevious}</p>
                                <span>{formatNumber(item.tvpi.dpiPreviousVal, 2)}x</span>
                              </div>
                            </div>
                             <div className="coinvestSummary-chart-labels">
                              <p>{formatNumber(item.tvpi.tvpiTotalCurrent, 2)}x</p>
                              <p>{formatNumber(item.tvpi.tvpiTotalPrevious, 2)}x</p>
                            </div> 
                            <Chart
                              id="chart"
                              dataSource={item.tvpiChart}
                              height={160}
                              palette={palette}
                              legend={{ visible: false }}
                              animation={false}
                              argumentAxis={{
                                visible: false,

                                grid: {
                                  visible: false,
                                },
                                tick: { visible: false },
                              }}
                              valueAxis={{
                                visible: false,
                                maxValueMargin: 0.05,
                                grid: {
                                  visible: false,
                                },
                                showZero: true,
                                tick: { visible: false },
                                label: {
                                  visible: false,
                                },
                              }}
                            >
                              <ArgumentAxis >
{/*                                 <Label
                                  visible={false}
                                  font={{  size: '11px' }}
                                  customizeText={(e: any) => {
                                    let fundLabel = item.tvpiChart.find((it: any) => it.quarter === e.value)
                                    if(fundLabel) {
                                      return `${e.value}
                                      Total ${formatNumber(Number(fundLabel.dpi) + Number(fundLabel.rpi))}x`;
                                    }
                                    return '';
                                  }}
                                />
*/}                            </ArgumentAxis>

                              <CommonSeriesSettings
                                argumentField="quarter"
                                type="stackedbar"
                                barWidth={50}
                              />
                              <Series valueField="rpi" name="RPI">
                              </Series>
                              <Series valueField="dpi" name="DPI" />
                            </Chart>
                          </div>
                        </div>
                      </div>
                    ))}
                  </section>
                </main>
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

export default CoinvestSummary;
