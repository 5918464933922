import ReportHeader from "../00-Header/ReportHeader";
import { IGlossary } from "./IGlossary";

const GlossaryP5Es = ({ templateData, date }: IGlossary) => (
  <div id="glossary" contentEditable className="pagebreak">
    <ReportHeader
      programName={templateData.programName}
      confidential="CONFIDENCIAL"
      date={date}
      section="Glosario de términos"
      page={templateData.page}
    />

    <main className="glossary-last">
      <section className="glossary-content">
        <div className="glossary-term-list">
          <p className="glossary-term-item-bold">Recap</p>
          <p className="glossary-term-item">Recapitalización.</p>
          <p className="glossary-term-item-bold">RoW</p>
          <p className="glossary-term-item">
            “Rest of the World” / Resto del Mundo, generalmente se refiere a
            zonas geográficas fuera de las principales (p. ej. Europa y Estados
            Unidos) a las que se hace referencia explícita.
          </p>
          <p className="glossary-term-item-bold">RVPI</p>
          <div className="glossary-term-item-special">
            <p className="glossary-term-item-special-text">
              “Residual Value to Paid in Capital”. Valor Residual de la Cartera
              Viva sobre el importe desembolsado, calculado como:
            </p>
            <div className="glossary-term-item-form">
              <p>RVPI =</p>
              <div>
                <p className="glossary-term-item-lined">
                  Valor Liquidativo ∗ Número de Participaciones Vivas
                </p>
                <p>Total Desembolsado</p>
              </div>
            </div>
          </div>
          <p className="glossary-term-item-bold">S.G.I.I.C.</p>
          <p className="glossary-term-item">
            Sociedad Gestora de Instituciones de Inversión Colectiva.
          </p>
          <p className="glossary-term-item-bold">SAU</p>
          <p className="glossary-term-item">Sociedad Anónima Unipersonal.</p>
          <p className="glossary-term-item-bold">Tamaño del Fondo</p>
          <p className="glossary-term-item">
            Importe acumulado de Compromisos de Inversión por parte de
            inversores a una fecha determinada.
          </p>
          <p className="glossary-term-item-bold">Tamaño Objetivo</p>
          <p className="glossary-term-item">
            Refleja la estimación del importe acumulado de Compromisos de
            Inversión por parte de inversores una vez finalice el Período de
            Comercialización de un fondo o FdFs.
          </p>
          <p className="glossary-term-item-bold">TIR</p>
          <p className="glossary-term-item">
            Tasa Interna de Retorno. Es una medida de rentabilidad anualizada
            sobre una inversión que tiene cash flows negativos y positivos
            (capital calls y distribuciones) a lo largo de los varios años de
            plazo de tenencia por parte de un inversor.
          </p>
          <p className="glossary-term-item-bold">Track Record</p>
          <p className="glossary-term-item">
            Retornos históricos obtenidos por fondos o FdFs predecesores
            gestionados por una misma sociedad gestora o equipo.
          </p>
          <p className="glossary-term-item-bold">Valoración</p>
          <p className="glossary-term-item">
            MOIC / TVPI / Múltiplo sobre Capital invertido (ver MOIC o TVPI).
          </p>
          <p className="glossary-term-item-bold">Venture Capital</p>
          <p className="glossary-term-item">
            Capital Privado en compañías que se encuentran en etapas tempranas
            de desarrollo.
          </p>
          <p className="glossary-term-item-bold">Write-off</p>
          <p className="glossary-term-item">
            Minusvalía realizada sobre el capital invertido en una empresa o
            activo.
          </p>
          <p className="glossary-term-item-bold">Yield</p>
          <p className="glossary-term-item">
            Distribución de carácter recurrente (generalmente anual).
          </p>
        </div>
      </section>
    </main>
  </div>
);

export default GlossaryP5Es;
