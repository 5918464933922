import { notification } from 'antd'
import { useEffect, useState } from 'react'

const useProcedures = (props: any) => {
	const [quartersList, setQuartersList] = useState<any[]>([])
	const [selectedQuarter, setSelectedQuarter] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false)
	const { setIsProceduresModalOpen, proceduresApi} = props

	useEffect(() => {
		fillQuarters()
	}, [])
	
	const closeProceduresModal = () => {
		setIsProceduresModalOpen(false)
	}

	const onChangeQuarter = (value: string) => {
		setSelectedQuarter(value)
	}

	const startProcess = async(process: string) => {
		setLoading(true)
		await proceduresApi.executeProcedures(selectedQuarter, process).then((res:any) => {
			if(res?.success){
				setLoading(false)
				notification.success({ message: 'Process started successfully' })
			}else{
				setLoading(false)
				notification.error({ message: 'Error starting the process' })
			}
		})
	}

	const fillQuarters = () => {
		const dates = ['03-31', '06-30', '09-30', '12-31']
		const currentYear = new Date().getFullYear()
		const lastYear = currentYear - 1
		const quarters = []
		for (let i = 0; i < 2; i++) {
			for (let j = 0; j < 4; j++) {
				quarters.push({
					value: `${i === 0 ? lastYear : currentYear}-${dates[j]}`,
					label: ` Q${j + 1} ${i === 0 ? lastYear : currentYear}`
				})
			}
		}
		setQuartersList(quarters)
	}

  return {
		closeProceduresModal,
		onChangeQuarter,
		startProcess,
		quartersList,
		selectedQuarter,
		loading
  }
}

export default useProcedures