import { Col, Row } from "antd";
import dayjs from 'dayjs';

import { IFundFigures } from "./IFundFigures";

import "./FundFigures.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";

const FundFigures = ({ templateData, translations, formatNumber }: IFundFigures) => (
	<div id="fundFigures" contentEditable className="pagebreak">
		<ReportHeader
			programName={templateData.programName}
			confidential={translations.confidential}
			date={`${translations[templateData.month]} ${templateData.year}`}
			section={translations.section}
			page={templateData.page}
		/>
		<Row className="content" align="middle" gutter={[0, 0]}>
			<Col span={24}>
				<main className="fundFigures">
					<section className="fundFigures-content">
						<p className="fundFigures-subtitle">
							{translations.fundFiguresUpdate} 
						</p>
						<table className="fundFigures-table">
							<colgroup>
								<col style={{width: "300px"}} />
								<col style={{width: "100px"}} />
								<col style={{width: "50px"}} />
								<col style={{width: "30px"}} />
								<col style={{width: "100px"}} />
								<col style={{width: "40px"}} />
								<col style={{width: "30px"}} />
								<col style={{width: "90px"}} />
							</colgroup>
							<thead>
							<tr className="fundFigures-table-beforeBold">
								<th className="fundFigures-table-title"></th>
								<th className="fundFigures-table-title fundFigures-table-beforeBold" colSpan={3}>
									{translations.currentQuarter}
								</th>
								<th className="fundFigures-table-title" colSpan={3}>
									{translations.previousQuarter}
								</th>
								<th className="fundFigures-table-title">
									{translations.variation}
								</th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td className="fundFigures-table-subtitle">
									{translations.totalCommitment}
								</td>
								<td className="fundFigures-table-coloredCells">
									{`€${formatNumber(templateData.commitCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-coloredCells">
									{`${formatNumber(templateData.commitCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-lightGrey">
									(1)
								</td>
								<td>{`€${formatNumber(templateData.commitPQValue, 0)}`}</td>
								<td>{`${formatNumber(templateData.commitPQPercentage, 0)}%`}</td>
								<td className="fundFigures-table-lightGrey">(1)</td>
								<td>{`€${formatNumber(templateData.commitVariation, 0)}`}</td>
							</tr>
							<tr>
								<td>{translations.totalPaidCapital}</td>
								<td className="fundFigures-table-coloredCells">
									{`€${formatNumber(templateData.drawdownCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-coloredCells">
									{`${formatNumber(templateData.drawdownCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-lightGrey">
									(1)
								</td>
								<td>{`€${formatNumber(templateData.drawdownPQValue, 0)}`}</td>
								<td>{`${formatNumber(templateData.drawdownPQPercentage, 0)}%`}</td>
								<td className="fundFigures-table-lightGrey">(1)</td>
								<td>{`€${formatNumber(templateData.drawdownVariation, 0)}`}</td>
							</tr>
							<tr>
								<td>{translations.cumulativeDistributions}</td>
								<td className="fundFigures-table-coloredCells">
									{`€${formatNumber(templateData.distCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-coloredCells">
									{`${formatNumber(templateData.distCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-lightGrey">
									(2)
								</td>
								<td>{`€${formatNumber(templateData.distPQValue, 0)}`}</td>
								<td>{`${formatNumber(templateData.distPQPercentage, 0)}%`}</td>
								<td className="fundFigures-table-lightGrey">(2)</td>
								<td>{`€${formatNumber(templateData.distVariation, 0)}`}</td>
							</tr>
							<tr className="fundFigures-table-beforeBold">
								<td>{translations.unfundedCommitment}</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-beforeBold">
									{`€${formatNumber(templateData.pendCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-beforeBold">
									{`${formatNumber(templateData.pendCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-lightGrey fundFigures-table-beforeBold">
									(1)
								</td>
								<td>{`€${formatNumber(templateData.pendPQValue, 0)}`}</td>
								<td>{`${formatNumber(templateData.pendPQPercentage, 0)}%`}</td>
								<td className="fundFigures-table-lightGrey">(1)</td>
								<td>{`€${formatNumber(templateData.pendVariation, 0)}`}</td>
							</tr>
							<tr >
								<td className="fundFigures-table-subtitle">
									{translations.totalCommitmentPortfolio}
								</td>
								<td className="fundFigures-table-coloredCells">
									{`€${formatNumber(templateData.commitFundsCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-coloredCells">
									{`${formatNumber(templateData.commitFundsCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-lightGrey">
									(1)
								</td>
								<td>
									{`€${formatNumber(templateData.commitFundsPQValue, 0)}`}
								</td>
								<td>{`${formatNumber(templateData.commitFundsPQPercentage, 0)}%`}</td>
								<td className="fundFigures-table-lightGrey">(1)</td>
								<td>
									{`€${formatNumber(templateData.commitFundsVariation, 0)}`}
								</td>
							</tr>
							<tr>
								<td>{translations.totalInvestedPortfolio}</td>
								<td className="fundFigures-table-coloredCells">
									{`€${formatNumber(templateData.drawdownFundsCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-coloredCells">
									{`${formatNumber(templateData.drawdownFundsCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-lightGrey">
									(2)
								</td>
								<td>
									{`€${formatNumber(templateData.drawdownFundsPQValue, 0)}`}
								</td>
								<td>{`${formatNumber(templateData.drawdownFundsPQPercentage, 0)}%`}</td>
								<td className="fundFigures-table-lightGrey">(2)</td>
								<td>
									{`€${formatNumber(templateData.drawdownFundsVariation, 0)}`}
								</td>
							</tr>
							<tr className="fundFigures-table-beforeBold">
								<td>{translations.cumulativeDistributionsPortfolio}</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-beforeBold">
									{`€${formatNumber(templateData.distFundsCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-beforeBold">
									{`${formatNumber(templateData.distFundsCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-lightGrey fundFigures-table-beforeBold">
									(2)
								</td>
								<td>{`€${formatNumber(templateData.distFundsPQValue, 0)}`}</td>
								<td>{`${formatNumber(templateData.distFundsPQPercentage, 0)}%`}</td>
								<td className="fundFigures-table-lightGrey">(2)</td>
								<td>
									{`€${formatNumber(templateData.distFundsVariation, 0)}`}
								</td>
							</tr>
							<tr>
								<td className="fundFigures-table-subtitle">
									{translations.totalFairValue}
								</td>
								<td className="fundFigures-table-coloredCells">
									{`€${formatNumber(templateData.portfolioCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-coloredCells">
									{`${formatNumber(templateData.portfolioCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-lightGrey">
									(2)
								</td>
								<td>{`€${formatNumber(templateData.portfolioPQValue, 0)}`}</td>
								<td>{`${formatNumber(templateData.portfolioPQPercentage, 0)}%`}</td>
								<td className="fundFigures-table-lightGrey">(2)</td>
								<td>
									{`€${formatNumber(templateData.portfolioVariation, 0)}`}
								</td>
							</tr>
							<tr className="fundFigures-table-beforeBold">
								<td>{translations.netCashPosition}</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-beforeBold">
									{`€${formatNumber(templateData.netCashCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-beforeBold">
									{`${formatNumber(templateData.netCashCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-lightGrey fundFigures-table-beforeBold">
									(2)
								</td>
								<td>{`€${formatNumber(templateData.netCashPQValue, 0)}`}</td>
								<td>{`${formatNumber(templateData.netCashPQPercentage, 0)}%`}</td>
								<td className="fundFigures-table-lightGrey">(2)</td>
								<td>{`€${formatNumber(templateData.netCashVariation, 0)}`}</td>
							</tr>
							<tr>
								<td className="fundFigures-table-subtitle fundFigures-table-green">
									{translations.totalNetAssetValue}
								</td>
								<td className="fundFigures-table-green">
									{`€${formatNumber(templateData.totalNavCQValue, 0)}`}
								</td>
								<td className="fundFigures-table-green">
									{`${formatNumber(templateData.totalNavCQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-green fundFigures-table-lightGrey">
									(2)
								</td>
								<td className="fundFigures-table-green">
									{`€${formatNumber(templateData.totalNavPQValue, 0)}`}
								</td>
								<td className="fundFigures-table-green">
									{`${formatNumber(templateData.totalNavPQPercentage, 0)}%`}
								</td>
								<td className="fundFigures-table-green fundFigures-table-lightGrey">
									(2)
								</td>
								<td className="fundFigures-table-green">
									{`€${formatNumber(templateData.totalNavVariation, 0)}`}
								</td>
							</tr>
							<tr className="fundFigures-table-rows">
								<td>{translations.grossMultipleCost}</td>
								<td className="fundFigures-table-coloredCells" colSpan={3}>
									{`${formatNumber(templateData.grossMocCQ)}x`}
								</td>
								<td colSpan={3}>{`${formatNumber(templateData.grossMocPQ)}x`}</td>
								<td>{`${formatNumber(templateData.grossMocVariation)}x`}</td>
							</tr>
							<tr className="fundFigures-table-rows fundFigures-table-beforeBold">
								<td>{translations.grossIRR}</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-beforeBold" colSpan={3}>
									{`${formatNumber(templateData.grossTirCQ)}%`}
								</td>
								<td colSpan={3}>{`${formatNumber(templateData.grossTirPQ)}%`}</td>
								<td>{`${formatNumber(templateData.grossTirVariation)}%`}</td>
							</tr>
							<tr className="fundFigures-table-rows">
								<td className="fundFigures-table-subtitle">
									{translations.distributionsPaidCapital}
								</td>
								<td className="fundFigures-table-coloredCells" colSpan={3}>
									{`${formatNumber(templateData.dpiCQ)}x`}
								</td>
								<td colSpan={3}>{`${formatNumber(templateData.dpiPQ)}x`}</td>
								<td>{`${formatNumber(templateData.dpiVariation)}x`}</td>
							</tr>
							<tr className="fundFigures-table-rows">
								<td>{translations.residualValuePaidCapital}</td>
								<td className="fundFigures-table-coloredCells" colSpan={3}>
									{`${formatNumber(templateData.rpiCQ)}x`}
								</td>
								<td colSpan={3}>{`${templateData.rpiPQ}x`}</td>
								<td>{`${formatNumber(templateData.rpiVariation)}x`}</td>
							</tr>
							<tr className="fundFigures-table-rows">
								<td>{translations.totalValuePaidCapital}</td>
								<td className="fundFigures-table-coloredCells" colSpan={3}>
									{`${formatNumber(templateData.tvpiCQ)}x`}
								</td>
								<td colSpan={3}>{`${formatNumber(templateData.tvpiPQ)}x`}</td>
								<td>{`${formatNumber(templateData.tvpiVariation)}x`}</td>
							</tr>
							<tr className="fundFigures-table-rows">
								<td>{translations.netIRR}</td>
								<td className="fundFigures-table-coloredCells" colSpan={3}>
									{`${formatNumber(templateData.netTirCQ)}%`}
								</td>
								<td colSpan={3}>{`${formatNumber(templateData.netTirPQ)}%`}</td>
								<td>{`${formatNumber(templateData.netTirVariation)}%`}</td>
							</tr>
							<tr className="fundFigures-table-rows">
								<td>{translations.paidCapitalToComCapital}</td>
								<td className="fundFigures-table-coloredCells fundFigures-table-beforeBold" colSpan={3}>
								{`${formatNumber(templateData.drawdownCommitCQ, 1)}%`}
								</td>
								<td colSpan={3}>{`${formatNumber(templateData.drawdownCommitPQ, 1)}%`}</td>
								<td>{`${formatNumber(templateData.drawdownCommitVariation, 1)}%`}</td>
							</tr>
							</tbody>
						</table>
						<table>
							<tr>
								<td className="fundFigures-references">
									<strong>(1)</strong> {translations.ref1}
								</td>
								<td className="fundFigures-references">
									<strong>(2)</strong> {translations.ref2}
								</td>
								<td className="fundFigures-references">
									<strong>(3)</strong> {translations.ref3}
								</td>
							</tr>
						</table>
					</section>					
				</main>
			</Col>
			<div className="fundFigures-table-img-container">
				<img className="fundFigures-table-img"
					src="https://qualitasfunds.blob.core.windows.net/assets/image-gallery/reporting/img-fundFigures.jpeg"
					alt="montañas"
				/>
			</div>
		</Row>
	</div>
);

export default FundFigures;