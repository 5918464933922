import { INewFunds } from "./INewFunds";

import "./NewFunds.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";
import { Col, Row } from "antd";
import { GlobalOutlined } from "@ant-design/icons";

const NewFunds = ({ templateData, translations, formatNumber}: INewFunds) => {

  let groups: any[] = []

  let groupsCopy = [...groups];
  if (templateData.newFunds) {
    templateData.newFunds.forEach((item: any, index: any) => {
      const groupIndex = Math.floor(index / 4);
      if (!groupsCopy[groupIndex]) {
        groupsCopy[groupIndex] = [];
      }
      groupsCopy[groupIndex].push(item);
    });
  }
  groups = groupsCopy;

  let page = templateData.page - 1;
  return (
    <>
      {groups.map((group: any) => {
        page++;
        return (
          <div id="newFunds" contentEditable className="pagebreak">
            <ReportHeader
              confidential={translations.confidential}
              programName={templateData.programName}
              date={`${translations[templateData.month]} ${templateData.year}`}
              section={translations.section}
              page={page}
            />
            <Row className="content" align="top" gutter={[0, 0]}>
              <Col span={24}>
                <main className="newFunds">
                  <p className="newFunds-subtitle">
                    {translations.section}
                  </p>
                  <section className="newFunds-content">
                    {group.map((item: any, index: number) => (
                      <div className="newFunds-item" key={index}>
                        <p className="newFunds-title">
                          {item.name}
                          <span>
                            {item.country} | {item.type}
                          </span>
                        </p>
                        <div className="newFunds-headerInfo">
                          <div className="newFunds-headerInfo-item">
                            <p>{translations.size}</p>
                            <p>€{formatNumber(item.size, 1)}M</p>
                          </div>
                          <div className="newFunds-headerInfo-item">
                            <p>{translations.commitment}</p>
                            <p>€{formatNumber(item.commitment, 1)}M</p>
                          </div>
                          <div className="newFunds-headerInfo-item">
                            <p>% Qualitas</p>
                            <p>{formatNumber(item.qfund, 2)}%</p>
                          </div>
                          <div className="newFunds-headerInfo-item">
                            <p>{translations.investmentDate}</p>
                            <p>{item.investmentDate}</p>
                          </div>
                          <div className="newFunds-headerInfo-item">
                            <p>{translations.target}</p>
                            <p>{item.target}</p>
                          </div>
                          <div className="newFunds-headerInfo-item">
                            <p>{translations.strategy}</p>
                            <p>{item.strategy}</p>
                          </div>
                          <div className="newFunds-headerInfo-item">
                            <img
                              className="newFunds-logo"
                              src={item.logo}
                              alt="logo"
                            />
                          </div>
                        </div>
                        <div className="newFunds-info">
                          <div className="newFunds-info-text">
                            <div  dangerouslySetInnerHTML={{ __html: item.description }}>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </section>
                </main>
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

export default NewFunds;
