import { Col, Row } from "antd";
import PieChart, {
  Series,
  Legend,
  Title,
  Font,
} from "devextreme-react/pie-chart";
import { PieChartType } from "devextreme/viz/pie_chart";

import { IFundCharts } from "./IFundCharts";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "./FundCharts.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";


const FundCharts = ({ templateData, translations, formatNumber }: IFundCharts) => {
  const sumTotals = (array: any) => {
    let total: number = 0;
    for (let i = 0; i < array.length; i++) {
      total += Number(array[i].val);
    }
    return total
  }

  const customText = (e: any, array: any[], wordToSearch: string) => {
    const value = array.find(item => item[wordToSearch] === e);

    let total = sumTotals(array); 
    let percentage =  formatNumber((value.val/total * 100), 2) + '%';

	if (e === "Raw materials & natural resources") {
		e = "Raw materials ";
	  }
	  if (e === "Financial & insurance services") {
		e = "Financial ";
	  }
  

    let newName = e.split(" ");
    if (newName.length === 2) {
      e = newName[0] + "\n" + newName[1];
    }
    if (newName.length === 3) {
      e = newName[0] + "\n" + newName[2];
    }
    return `${e} ${"\n"} ${percentage}`;
  };

  const sortLegendItems = (items: any[]) => {
    return items.sort((a: any, b: any) => {
      let itemA = a.text.toLowerCase();
      let itemB = b.text.toLowerCase();
      if (itemA < itemB) return -1;
      if (itemA > itemB) return 1;
      return 0;
    });
  }

  const commonProps = {
    animation: { enabled: false },
    type: "doughnut" as PieChartType,
    legend: {
      //margin: { top: 50 },
      visible: true,
      rowItemSpacing: 12,
	  rowCount: 6,
      font: { family: "Lato", color: "black", size: 9 },
      customizeItems: sortLegendItems,
      markerSize: 7	  
    },
    palette: ["#d9d9d9", "#919191", "#000"],
    minSegmentSize: "150px",
    commonSeriesSettings: {
      argumentField: "column",
      type: "rangeBar",
      minBarSize: 2,
      customizeLabel() {
        return {
          backgroundColor: "none",
        };
      },
    },
  };

  const renderCenter = (number: any)=> {  
    return (
    <svg>
      <circle cx="100" cy="110" r={15} fill="#ffffff"></circle>
      <text textAnchor="middle" x="100" y="120" style={
        { fontSize: 26, fontFamily: '"Palatino", serif', fontWeight: 500 }
        }>
        <tspan x="100">{number}</tspan>
      </text>
    </svg>
  );}

  return (
    <div id="fundCharts" contentEditable className="pagebreak">
      <ReportHeader
        confidential={translations.confidential}
        programName={templateData.programName}
        date={`${translations[templateData.month]} ${templateData.year}`}
        section={translations.section}
        page={templateData.page}
      />
      <p className="fundCharts-subtitle">{translations.fundTitle}</p>
      <div className="fundsCharts-container">
        <div className="fundsCharts-infoBox">
          <p>{translations.navLabel}</p>
          <p>€{formatNumber(templateData.nav, 1)}M</p>
        </div>
        <div className="fundsCharts-infoBox">
          <p>{translations.tvpiLabel}</p>
          <p>{formatNumber(templateData.tvpi, 2)} x</p>
        </div>
        <div className="fundsCharts-infoBox">
          <p>{translations.dpiLabel}</p>
          <p>{formatNumber(templateData.dpi, 2)} x</p>
        </div>
        <div className="fundsCharts-infoBox">
          <p>{translations.netTirLabel}</p>
          <p>{formatNumber(templateData.netTir, 1)} %</p>
        </div>
        <div className="fundsCharts-infoBox">
          <p>{translations.companiesLabel}</p>
          <p>{templateData.companies}</p>
        </div>
        <div className="fundsCharts-infoBox">
          <p>{translations.gpsLabel}</p>
          <p>{templateData.gps}</p>
        </div>
      </div>
      <Row align="middle">
        <Col>
          <main className="fundCharts">
            <section className="fundCharts-content">
              <div className="fundsChars-chartsContainer">
                <PieChart
                  id="fundsChars-chart3"
                  dataSource={templateData.currencyChart}
                  {...commonProps}
                  sizeGroup="piesGroup"
                  centerRender={()=> renderCenter(templateData.currencyChart.length)}
                >
                  <Title text={translations.fxDiversificationSubtitle}>
                    <Font family={"Lato"} weight={500} size={14} />
                  </Title>
                  <Series
                    argumentField="currency"
                    valueField="val"
                  />
                  <Legend
                    orientation="horizontal"
                    horizontalAlignment="center"
					verticalAlignment="bottom"
                    customizeText={(e: any) => customText(e.pointName, templateData.currencyChart, 'currency')}
                  />
                </PieChart>
                <PieChart
                  id="fundsChars-chart2"
                  dataSource={templateData.regionChart}
                  {...commonProps}
                  sizeGroup="piesGroup"
                  centerRender={()=> renderCenter(templateData.regionChart.length)}
                >
                  <Title text={translations.regionDiversificationSubtitle}>
                    <Font family={"Lato"} weight={500} size={14} />
                  </Title>
                  <Series
                    argumentField="region"
                    valueField="val"
                    label={{visible: false,}}                  
                    />
                  <Legend
                    orientation="horizontal"
                    horizontalAlignment="center"
					verticalAlignment="bottom"
                    customizeText={(e: any) => customText(e.pointName, templateData.regionChart, 'region')}
                  />                
                </PieChart>
                <PieChart
                  id="fundsChars-chart1"
                  dataSource={templateData.sectorChart}
                  {...commonProps}
                  sizeGroup="piesGroup"
				  width={400}
                  centerRender={()=> renderCenter(templateData.sectorChart.length)}
                >
                  <Title text={translations.sectorDiversificationSubtitle}>
                    <Font family={"Lato"} weight={500} size={14} />
                  </Title>
                  <Series
                    argumentField="sector"
                    valueField="val"
                    label={{visible: false,}}                  
                    />
                  <Legend
                    orientation="horizontal"
                    horizontalAlignment="center"
					verticalAlignment="bottom"
                    customizeText={(e: any) => customText(e.pointName, templateData.sectorChart, 'sector')}
                  />
                </PieChart>
              </div>
            </section>
          </main>
        </Col>
      </Row>
    </div>
  );
};

export default FundCharts;
