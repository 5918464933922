import ReportHeader from "../00-Header/ReportHeader";
import { IGlossary } from "./IGlossary";

const GlossaryP4Es = ({ templateData, date }: IGlossary) => (
  <div id="glossary" contentEditable className="pagebreak">
    <ReportHeader
      programName={templateData.programName}
      confidential="CONFIDENCIAL"
      date={date}
      section="Glosario de términos"
      page={templateData.page}
    />
    <main className="glossary">
      <section className="glossary-content">
        <div className="glossary-term-list">
          <p className="glossary-term-item-bold">LTM</p>
          <p className="glossary-term-item">
            “Last Twelve Months” / Últimos Doce Meses.
          </p>
          <p className="glossary-term-item-bold">Mercado Primario</p>
          <p className="glossary-term-item">
            Realización de un compromiso de inversión en un fondo de nueva
            creación. La cartera de dicho fondo está “vacía”, sin inversiones
            subyacentes, en el momento de su lanzamiento.
          </p>
          <p className="glossary-term-item-bold">Mercado Secundario</p>
          <p className="glossary-term-item">
            Adquisición de un compromiso de inversión en un fondo ya existente a
            un inversor que desea vender su participación. El nuevo inversor
            sustituye al anterior en idénticos términos y condiciones, comprando
            la porción del compromiso de inversión que ya está desembolsada a un
            precio acordado entre las partes y asumiendo el compromiso pendiente
            de desembolso. Al llevarse a cabo sobre fondos que cuentan con
            varios años de vida, ya cuentan con inversiones subyacentes en
            cartera.
          </p>
          <p className="glossary-term-item-bold">MOIC</p>
          <div className="glossary-term-item-special">
            <p className="glossary-term-item-special-text">
              “Multiple on Invested Capital” / Múltiplo sobre Capital Invertido,
              calculado como:
            </p>
            <div className="glossary-term-item-form">
              <p>MOIC =</p>
              <div>
                <p className="glossary-term-item-lined">
                  Valor Total de las Inversiones
                </p>
                <p>Coste Total de la Inversión</p>
              </div>
            </div>
          </div>
          <p className="glossary-term-item-bold">Múltiplo de Compra</p>
          <p className="glossary-term-item">
            Precio pagado por una inversión en el momento de la compra,
            generalmente expresado como un múltiplo sobre el EBITDA de la
            empresa o activo comprado.
          </p>
          <p className="glossary-term-item-bold">Período de Comercialización</p>
          <p className="glossary-term-item">
            Período de tiempo en el cual los inversores pueden realizar
            compromisos de inversión en un fondo o FdFs. Una vez finalizado
            dicho Período de comercialización, el fondo o FdFs se cierra,
            dejando de admitir inversores adicionales en primario (ver mercado
            primario y tamaño objetivo).
          </p>
          <p className="glossary-term-item-bold">Período de Inversión</p>
          <p className="glossary-term-item">
            Período de tiempo en el cual un fondo o FdFs puede llevar a cabo sus
            inversiones subyacentes. En el caso de los FdFs aplica al Período en
            el que han de llevarse a cabo los compromisos en los fondos
            subyacentes si bien a su vez, los propios fondos subyacentes tendrán
            su Período de inversión aplicable para llevar a cabo la adquisición
            de las inversiones que formarán parte de sus carteras.
          </p>
          <p className="glossary-term-item-bold">Portfolio</p>
          <p className="glossary-term-item">
            Cartera que agrupa varias inversiones subyacentes.
          </p>
          <p className="glossary-term-item-bold">Private Equity</p>
          <p className="glossary-term-item">
            Capital Privado. Toma de participaciones mayoritarias o minoritarias
            en empresas no cotizadas durante un plazo de tiempo que permita la
            ejecución del plan de negocio establecido en el momento de la
            adquisición, con el objetivo de realizar una plusvalía al vender la
            empresa una vez transcurrido dicho plazo.
          </p>
          <p className="glossary-term-item-bold">Proforma</p>
          <p className="glossary-term-item">
            Magnitud que refleja operaciones desarrolladas con posterioridad a
            la fecha de la valoración a la que hace referencia.
          </p>
          <p className="glossary-term-item-bold">PTC</p>
          <p className="glossary-term-item">
            Patrimonio Total Comprometido (Ver Compromiso de Inversión).
          </p>
        </div>
      </section>
    </main>
  </div>
);

export default GlossaryP4Es;
