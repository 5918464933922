import { useEffect, useState } from "react";
import { Link, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Col, ConfigProvider, Layout, Menu, Row } from "antd";
import { SettingOutlined, FilePdfOutlined, WalletOutlined } from '@ant-design/icons';

import FeedbackModal, { useFeedbackModal } from '@qualitas/feedback-modal';

import ReportGenerator from "./Components/ReportGenerator/ReportGenerator";
import NetCash from "./Components/NetCash/NetCash";
import { Footer } from '@qualitas/navbar';

import { IPrivateContentLayout } from "./IPrivateContentLayout";
import MenuNavbar from "./Components/Menu/MenuNavbar";
import ProceduresModal from "./Components/ProceduresModal/ProceduresModal";

const { Item } = Menu;

const PrivateContentLayout = ({
	baseUrl,
	getAuthToken,
	logOut,
	userLogo,
	authProps
}: IPrivateContentLayout) => {
	const [menuOptionSelected, setMenuOptionSelected] = useState('2');
	const [isProceduresModalOpen, setIsProceduresModalOpen] = useState(false);
	const location = useLocation();
	const feedbackModalProps = useFeedbackModal({});
	const navigate = useNavigate();	

	useEffect(() => {
		if (location.pathname === '/netCash') {
			setMenuOptionSelected('3');
		}
	}, []);

	const onSelect = (e: any) => {
		setMenuOptionSelected(e.key)
	};
	
	return (
		<ConfigProvider
			theme={ {
				token: {
					colorPrimary: '#b3d615',
					colorInfo: '#b3d615',
					colorSuccess: '#8fac10',
					borderRadius: 0,
					borderRadiusXS: 0,
					borderRadiusSM: 0,
					borderRadiusLG: 0,
					boxShadow: '',
					boxShadowSecondary: '',
				},
				components: {
					Button: {
						primaryColor: 'rgb(0, 0, 0)',
					},
				},
			} }
		>
		<Layout className="layout">
			<MenuNavbar 
				getAuthToken={getAuthToken} 
				baseUrl={baseUrl} 
				logout={logOut}
				authProps={authProps}
				sendResetPasswordEmail={authProps.sendResetPasswordEmail}
				navigate={navigate}
			/>
			<ProceduresModal 
				setIsProceduresModalOpen={setIsProceduresModalOpen}
				isProceduresModalOpen={isProceduresModalOpen}
				baseUrl={baseUrl}
				getAuthToken={getAuthToken}
			/>
			<Row>
				<Col className="aside-menu">
					<Menu
						className="aside-menu-options"
						selectedKeys={[menuOptionSelected]}
						mode="inline"
						onSelect={onSelect}
					>
						{/* <Item title="Generate" key="1" icon={<FilePdfOutlined/>}><Link to="https://trimestral.qualitasfunds.com">Generate FoF</Link></Item>*/}						
						<Item title="Generate Direct" key="2" icon={<FilePdfOutlined/>}><Link to="/">Generate Report</Link></Item>
						<Item title="Net Cash" key="3" icon={<WalletOutlined/>}><Link to="/netCash">Net Cash</Link></Item>
						<Item title="Procedures" key="4" icon={<SettingOutlined />}>
							<button className="btn-process" onClick={()=> setIsProceduresModalOpen(true)}>
								Procedures
							</button>
						</Item>
					</Menu>
				</Col>
				<Col className="content">
					<Routes>
						<Route path="/" element={
							<ReportGenerator baseUrl={baseUrl} getAuthToken={getAuthToken} showServiceFeedbackModal={feedbackModalProps.showServiceFeedbackModal}/>
						}/>
						<Route path="/netCash" element={
							<NetCash baseUrl={baseUrl} getAuthToken={getAuthToken} showServiceFeedbackModal={feedbackModalProps.showServiceFeedbackModal}/>
						}/>
						<Route path="*" element={<Navigate to="/"/>}/>
					</Routes>
				</Col>
			</Row>
			
			<Footer/>
			<FeedbackModal {...feedbackModalProps}/>
		</Layout>
		</ConfigProvider>
	);
};

export default PrivateContentLayout;